<script setup>
import { ref, getCurrentInstance, onMounted, nextTick, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'
import Comfirm from '@/components//popup/Comfirm.vue'
import DataSelectPopup from '@/components/popup/DataSelectPopup.vue'
import { useStore } from "vuex";

const { t } = useI18n()
const vfmBasePopup = inject('vfmBasePopup')
const store = useStore()

let con = getCurrentInstance()
let search = {}


// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for (let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for (let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

const GROUP_AUTH_MST = ref()
const GROUP_AUTH_DTL_USER = ref()
const GROUP_AUTH_DTL_MENU = ref()
const GROUP_AUTH_DTL_USER_WO_GRP = ref()

let grids = {
    'GROUP_AUTH_MST': GROUP_AUTH_MST,
    'GROUP_AUTH_DTL_MENU': GROUP_AUTH_DTL_MENU,
    'GROUP_AUTH_DTL_USER': GROUP_AUTH_DTL_USER,
    'GROUP_AUTH_DTL_USER_WO_GRP': GROUP_AUTH_DTL_USER_WO_GRP,
}

onMounted(() => {
    console.log('==== 컨포넌트 리스트 ====')
    let tmpGridKeys = Object.keys(ng_core.common.currentPageInfo)

    for(let i = 0; i < tmpGridKeys.length; i++) {
        console.log('** ' + tmpGridKeys[i] /**+ ' : ' + t('COMPONENT.' + tmpGridKeys[i])*/)
        let tmpSearchKeys = Object.keys(search[tmpGridKeys[i]])
        let tmpSearchColumn = ' -- Search : { '
        for(let j = 0; j < tmpSearchKeys.length; j++) {
            tmpSearchColumn += tmpSearchKeys[j] + ' '
        }
        tmpSearchColumn += '}'
        console.log(tmpSearchColumn)
    }
})

/*************************************************
 * Grid 관련 : Start
 *************************************************/

/** 조회 */
function onSearch(compName) {
    for(let i = 0; i < compName.length; i++) {
        grids[compName[i]].value.grdSearchRef(search[compName[i]])
    }
}

/** 라디오 박스 조회 */
function onSearch2(compName) {
    debugger;
    for(let i = 0; i < compName.length; i++) {
        grids[compName[i]].value.grdSearchRef(search[compName[i]])
    }
}

/** 행 삭제 */
function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

/** 저장 */
function onSave(compName) {
    grids[compName].value.grdSave()
}


/** 행 선택 라디오 */
function onRowClickRadio(ui, row) {
    for(let i = 0; i < tabGrid.length; i++) {
        for(let j = 0; j < tabGrid[i].length; j++) {
            let tmpGrdNm = tabGrid[i][j];
            grids[tmpGrdNm].value.grdSearchRef(search[tmpGrdNm])
            search[tmpGrdNm].GROUP_CODE.value = row.GROUP_CODE
        }
    }
}

// /** 셀 버튼 이벤트 */
async function onGrdCellButton(sender, ui, event) {

}
function onGrd02InitFinish(sender, ui, event) {

}
/*************************************************
 * Grid 관련 : End
 *************************************************/

/*************************************************
 * Tab 관련 : Start
*************************************************/
let tabIndex = ref(0)
let tabGrid = [
    ['GROUP_AUTH_DTL_MENU'],
    ['GROUP_AUTH_DTL_USER', 'GROUP_AUTH_DTL_USER_WO_GRP'],
    
]

/** 탭 이동 */
function activateTab(a, b, c) {
    // 탭 페이지 리사이징
    tabPageContentResize()
}

/** 탭 리사이즈 */
function tabPageContentResize() {
    window.requestAnimationFrame(function () {
        let tmpGridKeys = Object.keys(grids)
        for (let i = 0; i < tmpGridKeys.length; i++) {
            grids[tmpGridKeys[i]].value.refresh()
        }
    });
}

/** 대상을 왼쪽으로 이동 */
async function onLeftMove() {
    let tmpDataList = grids['GROUP_AUTH_DTL_USER_WO_GRP'].value.getGrdCheckList()
    if(tmpDataList.length == 0) {
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0013') })
        return 
    }
    let rtnComfirm = await vfmBasePopup(Comfirm, { state: 'info', contents: t('MSG.CHK_ADD') })
    if(rtnComfirm == false) return

    tmpDataList.map(function(row) {
        var tmp_row = Object.assign({}, row, {
            ROWRADIO: 'N',
            METHOD: 'A',
            ROWCHECK: 'N',
            GROUP_CODE: grids.GROUP_AUTH_MST.value.getRowSelectedData().GROUP_CODE,
        })
        // 상위 정보를 같이 넘겨야 함****
        // treegrid 상위 처리 관계 없이 끝에 row추가
        grids['GROUP_AUTH_DTL_USER'].value.grdAddRow(tmp_row, 'last')
    })

    await grids['GROUP_AUTH_DTL_USER'].value.grdSave({ hideComfirm: 'Y'/**, hideMessageYN: 'Y', reloadYn: 'N' */ })

    grids['GROUP_AUTH_DTL_USER_WO_GRP'].value.grdSearchRef(null, null, { hideMessageYN: 'Y' })
}

/** 대상을 오른쪽으로 이동 */
async function onRightMove() {
    let tmpDataList = grids['GROUP_AUTH_DTL_USER'].value.getGrdCheckList()
    if(tmpDataList.length == 0) {
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0013') })
        return 
    }

    let rtn = await grids['GROUP_AUTH_DTL_USER'].value.grdDeleteWithDB()
    if(rtn) {
        grids['GROUP_AUTH_DTL_USER'].value.grdSearchRef()
        grids['GROUP_AUTH_DTL_USER_WO_GRP'].value.grdSearchRef()
    } else {
        alert('???????')
    }
}
/*************************************************
 * Tab 관련 : End
 *************************************************/
</script>
<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal @resize="doResize"
                :dbl-click-splitter="false">
                <!-- 상단 : Start -->
                <Pane>
                    <!-- 검색 조건 : Start -->
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="parentCon">
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{ t('GROUP_CODE') }}</label>
                                <input type="text" class="conInputText" v-model="search['GROUP_AUTH_MST'].GROUP_CODE.value" :placeholder="t('GROUP_CODE')" 
                                @keyup.enter="onSearch(['GROUP_AUTH_MST'])" :state="null">
                                <label for="input-none" class="conLabel">{{ t('GROUP_NAME') }}</label>
                                <input type="text" class="conInputText" v-model="search['GROUP_AUTH_MST'].GROUP_NAME.value" :placeholder="t('GROUP_NAME')" 
                                @keyup.enter="onSearch(['GROUP_AUTH_MST'])" :state="null">
                                <!-- menu_type -->
                                <label class="conLabel">{{t('MENU_TYPE')}}</label>
                                ADMIN<input type="radio" name="menu_type" value="adm" v-model="menuType" 
                                @click="onSearch2(['GROUP_AUTH_MST'])"/>
                                DEVELOPER<input type="radio" name="menu_type" value="user" v-model="menuType"
                                @click="onSearch2(['GROUP_AUTH_MST'])"/>
                                MOBILE<input type="radio" name="menu_type" value="mobile" v-model="menuType"
                                @click="onSearch2(['GROUP_AUTH_MST'])"/>

                            </div>
                            <div class="rightCon">
                                <button ref="read" @click="onSearch(['GROUP_AUTH_MST'])" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                <button ref="plus" @click="onAddRow('GROUP_AUTH_MST')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                <button ref="minus" @click="onRemoveRow('GROUP_AUTH_MST')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                <button ref="save" @click="onSave('GROUP_AUTH_MST')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003')}}</button>

                            </div>
                        </div>
                    </div>
                    <!-- 검색 조건 : End -->
                    <!-- 그리드 영역 : Start -->
                    <div class="resultGrid" style="height:calc(100% - 40px);">
                        <BaseGrid ref="GROUP_AUTH_MST" :refCode="'GROUP_AUTH_MST'"
                         @RowClickRadio="onRowClickRadio"/>
                    </div>
                    <!-- 그리드 영역 : End -->
                </Pane>
                <!-- 상단 : End   -->
                <!-- 하단 : Start -->
                <Pane>
                    <!-- Tabs : Start -->
                    <b-tabs ref="bottomTabs" small class="h-100" @activate-tab="activateTab">
                        <!-- Tab Page 01 : Start-->
                        <b-tab :title="$t('MENU_AUTH_MANAGE')" active>
                            <!-- 검색 조건 : Start -->
                            <div style="width:100%; height:40px; overflow:auto;">
                                 <div class="parentCon">
                                    <div class="leftCon ml-1 mr-1">
                                        <!-- -->
                                        <label for="input-none" class="conLabel">{{ $t('GROUP_CODE') }}</label>
                                        <input type="text" class="conInputText" v-model="search['GROUP_AUTH_DTL_MENU'].GROUP_CODE.value" :placeholder="$t('GROUP_CODE')" 
                                            @keyup.enter="onSearch(['GROUP_AUTH_DTL_MENU'])" :state="null">

                                        <label for="input-none" class="conLabel">{{ $t('GROUP_CODE') }}</label>
                                        <input type="text" class="conInputText" v-model="search['GROUP_AUTH_DTL_MENU'].GROUP_CODE.value" :placeholder="$t('GROUP_CODE')"
                                            @keyup.enter="onSearch(['GROUP_AUTH_DTL_MENU'])" :state="null">
                                    </div>
                                    <div class="rightCon">
                                        <button ref="read" @click="onSearch(['GROUP_AUTH_DTL_MENU'])" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                        <button ref="plus" @click="onAddRow('GROUP_AUTH_DTL_MENU')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('GROUP_AUTH_DTL_MENU')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('GROUP_AUTH_DTL_MENU')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003')}}</button>
                                    </div>
                                </div>
                            </div>
                            <!-- 검색 조건 : End -->
                            <!-- 그리드 영역 : Start -->
                            <div class="resultGrid" style="height:calc(100% - 40px);">
                                <BaseGrid ref="GROUP_AUTH_DTL_MENU" :refCode="'GROUP_AUTH_DTL_MENU'"/>
                            </div>
                        </b-tab>
                        <!-- 그리드 영역 : End -->
                        <!-- 그룹권한관리-->
                        <b-tab :title="$t('GROUP_AUTH_MANAGE')">
                            <div class="" style="height:100%; margin-bottom:10px">
                                <Splitpanes :orientation="'horizontal'" @resize="doResize">
                                    <Pane>
                                        <!-- grid 01 -->
                                        <div style="width:100%; height:40px; overflow:auto;">
                                            <div class="parentCon">
                                                <div class="rightCon">
                                                    <button @click="onLeftMove()" :title="t('BUTTON0014')"><font-awesome-icon icon="angle-left" /></button>
                                                    <button @click="onRightMove()" :title="t('BUTTON0015')"><font-awesome-icon icon="angle-right" /></button>
                                                    <button ref="save" @click="onSave('GROUP_AUTH_DTL_USER')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003')}}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="resultGrid" style="height:calc(100% - 80px);">
                                            <BaseGrid ref='GROUP_AUTH_DTL_USER' :refCode="'GROUP_AUTH_DTL_USER'"/>
                                        </div>
                                    </Pane>
                                    <Pane>
                                        <!-- grid 02 -->
                                        <div style="width:100%; height:40px; overflow:auto;">
                                            <div class="parentCon">
                                                <div class="rightCon">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="resultGrid" style="height:calc(100% - 80px);">
                                            <BaseGrid ref='GROUP_AUTH_DTL_USER_WO_GRP' :refCode="'GROUP_AUTH_DTL_USER_WO_GRP'"/>
                                        </div>
                                    </Pane>
                                </Splitpanes>
                            </div>
                        </b-tab>
                    </b-tabs>
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>