<script setup>
import { ref, getCurrentInstance, onMounted, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'

const { t } = useI18n()
const router = useRouter()
let con = getCurrentInstance()
let search = {}

const BOARDLIST = ref()
// const BOARDDETAIL = ref()
// const BOARDREPLY = ref()

let grids = {
    'BOARDLIST': BOARDLIST,
    // 'BOARDDETAIL': BOARDDETAIL,
    // 'BOARDREPLY': BOARDREPLY
}

// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for(let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for(let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

onMounted(() => {
    // Router Query : TABLENAME데이터 확인
    if(!!router && !!router.currentRoute.value.query && !!router.currentRoute.value.query.board) {
        let board = router.currentRoute.value.query.board;
        search['BOARDLIST'].TABLENAME.value = board;
    } else {
        search['BOARDLIST'].TABLENAME.value = "EVENT";
    }
});

function onSearch(compName) {
    // search[compName].TABLENAME.value = "EVENT";
    search[compName].PAGE_NO.value = -1
    search[compName].PAGE_SIZE.value = 20
    grids[compName].value.grdSearchRef(search[compName])
}

function onAddRow(compName) {
    grids[compName].value.grdAddRow({}, 'last')
}

function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

function onSave(compName) {
    grids[compName].value.grdSave({boardName: search[compName].TABLENAME.value})
}

// 게시판 이동
const onGotoBoard = () => {
    // window.open('/board/' + this.board, '_blank');
}

const gridButtonClick = (grid, ui, e) => {
    debugger;
}

const uploadImage = (e, a, b, c) => {
    debugger;
}

// function onSave(compName) {
//     grids[compName].value.grdSave()
// }

</script>
<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter"
                        horizontal
                        @resize="doResize" :dbl-click-splitter="false">
            <!-- 조회 상단-->
            <Pane>
                <div style="width:100%; height:40px; overflow:auto;">
                    <div class="parentCon">
                        <div class="leftCon ml-1 mr-1">
                            <label for="input-none" class="conLabel">{{t('TABLENAME')}}</label>
                            <input type="text" class="conInputText" v-model="search['BOARDLIST'].TABLENAME.value" :placeholder="t('TABLENAME')"
                                    @keyup.enter="onSearch('BOARDLIST')" :state="null" disabled>
                        </div>
                        <div class="leftCon ml-1 mr-1">
                            <label for="input-none" class="conLabel">{{t('SUBJECT')}}</label>
                            <input type="text" class="conInputText" v-model="search['BOARDLIST'].SUBJECT.value" :placeholder="t('SUBJECT')"
                                    @keyup.enter="onSearch('BOARDLIST')" :state="null">
                        </div>
                        <div class="leftCon ml-1 mr-1">
                            <label for="input-none" class="conLabel">{{t('CONTENTS')}}</label>
                            <input type="text" class="conInputText" v-model="search['BOARDLIST'].CONTENTS.value" :placeholder="t('CONTENTS')"
                                    @keyup.enter="onSearch('BOARDLIST')" :state="null">
                        </div>
                        <div class="leftCon ml-1 mr-1">
                            <label for="input-none" class="conLabel">{{$t('NOTICE_YN')}}</label>
                            <select class="conInputText" v-model="search['BOARDLIST'].NOTICE_YN.value">
                                <option value="">전체</option>
                                <option value="Y">Y</option>
                                <option value="N">N</option>
                            </select>
                        </div>
                        <div class="rightCon">
                            <button ref="read" @click="onSearch('BOARDLIST')" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                            <button ref="plus" @click="onAddRow('BOARDLIST')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                            <button ref="minus" @click="onRemoveRow('BOARDLIST')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                            <button ref="save" @click="onSave('BOARDLIST')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                        </div>
                    </div>
                </div>
                <div class="resultGrid" style="height:calc(100% - 40px);">
                    <BaseGrid ref="BOARDLIST" :refCode="'BOARDLIST'" @onGrdCellButtonClick="gridButtonClick"/>
                </div>
            </Pane>
            
        </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>