<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'

const { t } = useI18n()
let con = getCurrentInstance()
let search = {}
const USER_ACCESS_LOG = ref()

let grids = {
    'USER_ACCESS_LOG': USER_ACCESS_LOG,

}

// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for(let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for(let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}
/** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])


}
//라디오 박스
function onGrd01RowClick(row) {
    search.USER_ACCESS_LOG.LOG_SEQ.value = row.LOG_SEQ
    onSearch('USER_ACCESS_LOG')
}

// 날짜
let today = new Date();
let year = today.getFullYear();     // 년도
let month = today.getMonth() + 1;   // 월
let date = today.getDate();         // 날짜
let result = year + '-' + month + '-' + date

search.USER_ACCESS_LOG.START_DATE1.value = result
search.USER_ACCESS_LOG.START_DATE2.value = result

</script>

<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter"
                        horizontal
                        @resize="doResize" :dbl-click-splitter="false">
                <Pane>
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="parentCon">
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('LOG_SEQ')}}</label>
                                <input type="text" class="conInputText" v-model="search['USER_ACCESS_LOG'].LOG_SEQ.value" :placeholder="t('LOG_SEQ')"
                                        @keyup.enter="onPressEnterkeyToSubmitTop" :state="null">
                            </div>
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('START_DATE')}}</label>
                                <input type="text" class="conInputText" v-model="result" :placeholder="t('START_DATE')"
                                        @keyup.enter="onPressEnterkeyToSubmitTop" :state="null">
                            </div>
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('END_DATE')}}</label>
                                <input type="text" class="conInputText" v-model="result" :placeholder="t('END_DATE')"
                                        @keyup.enter="onPressEnterkeyToSubmitTop" :state="null">
                            </div>
                            <div class="rightCon">
                                <button ref="read" @click="onSearch('USER_ACCESS_LOG')" :title="t('BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="resultGrid" style="height:calc(100% - 40px);">
                        <BaseGrid ref="USER_ACCESS_LOG" :refCode="'USER_ACCESS_LOG'"
                            @rowclick="onGrd01RowClick"
                            v-bind:ComID="'USER_ACCESS_LOG'" 
                        />
                        <!-- <BaseGrid ref='COMMON_CODE_MST' 
                                @rowclick="onGrd01RowClick" 
                                @rowclickradio="onGrd01RowClickRadio"
                                v-bind:ComID="'COMMON_CODE_MST'" 
                                v-bind:MainCode="grd01basedata.COMPONENT_CODE"
                        />  -->
                    </div>
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>