<script setup>

import { defineProps, computed, defineEmits } from 'vue'
import { useStore } from "vuex";
import { useRouter } from 'vue-router'

const router = useRouter()
const store = useStore()
const props = defineProps(['opened'])
const emit = defineEmits(['changeOpen'])

/** 유저 정보 */
let userInfo = computed(() => store.state.NGCore.userInfo)

function changeLang(lang) {
    return

    // localStorage.setItem('languageCode', lang)
    // this.languageCode = lang.toUpperCase();

    // if (this.$store.state.auth.user) {
    //     var params = {
    //         methodcode: 'USER_LANG',
    //         SQLLOG_YN: 'N',             // db log 남길지 유무
    //         paramsdata: {
    //             RUN_USER_ID: this.$store.state.auth.user.USER_ID,
    //             LANG: this.languageCode,
    //         }
    //     }
    //     await this.$store.dispatch('nuisys/comonsyssave', params);
    //     this.$router.go();
    // }
}

function prfile() {
    router.push('/' + noligo_config.menuAreaType + '/profile')
}

function admin() {
    router.push('/' + noligo_config.menuAreaType)
}

function logout() {
    //sotre.dispatch('NGCore/logout')
    router.push('/')
}

function ChangeOpen() {
    emit('changeOpen', !props.opened)
}

</script>

<template>
    <div>
        <b-navbar toggleable="lg" type="dark" :class="{ navbaropen: props.opened, sidebarclose: !props.opened }">
            <b-navbar-brand href="#" @click="ChangeOpen">
                <font-awesome-icon icon="bars" :class="{ sideopen: props.opened }" class="th" />
            </b-navbar-brand>
            <span class="open-slide">
				<!-- <a href="#" @click="$emit('update:props.opened', props.opened = !props.opened)" >
					<font-awesome-icon icon="th" :class="{sideopen: props.opened}" class="th"/>
				</a> -->
			</span>
            <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->
            <b-collapse id="nav-collapse" is-nav>
                <!-- <div style="position:absolute; top:0;"> -->
                <b-navbar-nav class="ml-auto" :class="{ navrightopen: props.opened }" right>
                    <!-- <b-nav-item>
						<span style="color:white;"><font-awesome-icon icon="user-circle"/>AAA님(개발 1팀 / 사원)</span>
					</b-nav-item> -->

                    <b-nav-item >
                        <font-awesome-icon icon="user-circle" />{{ userInfo.NAME }} 님
                    </b-nav-item>
                    <b-nav-item-dropdown :text="languageCode" right>
                        <b-dropdown-item href="#" @click="changeLang('ko')">KO</b-dropdown-item>
                        <b-dropdown-item href="#" @click="changeLang('en')">EN</b-dropdown-item>
                        <!-- <b-dropdown-item href="#">JP</b-dropdown-item>
						<b-dropdown-item href="#">CN</b-dropdown-item> -->
                    </b-nav-item-dropdown>
                    <!-- <b-nav-item @click.prevent="logout">
						<font-awesome-icon icon="sign-out-alt"/>Log_out
					</b-nav-item> -->
                    <!-- <b-nav-item>
						<span style=""><font-awesome-icon icon="cog"/></span>
					</b-nav-item> -->
                    <b-nav-item-dropdown right>
                        <template #button-content style="background-color: none;">
                            <span style=""><font-awesome-icon icon="cog" /></span>
                        </template>
                        <b-dropdown-item href="#"
                            @click.prevent="login">Login</b-dropdown-item>

                        <b-dropdown-item href="#"
                            @click.prevent="prfile">Profile</b-dropdown-item>
                        <b-dropdown-item 
                            @click.prevent="admin">Admin</b-dropdown-item>
                        <b-dropdown-item href="#"
                            @click.prevent="logout">Logout</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>


