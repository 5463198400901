<script setup>
import { ref, onMounted, computed, defineProps } from 'vue'
import { useStore } from "vuex";
const store = useStore()

const props = defineProps(['opened'])

let currentMessage = computed(() => {
    if(store.state.MQ.messages.length == 0) {
        return ''
    } else {
        return store.state.MQ.messages[store.state.MQ.messages.length-1].message
    }
})
</script>

<template>
    <div id="statusbar" :class="{navbaropen: opened, sidebarclose: !opened}">
      	{{ currentMessage }}
    </div>
</template>
