<script setup>
import { getCurrentInstance, onMounted, ref, inject } from "vue";
import { useRouter } from 'vue-router'
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'
import Alert from '../components/popup/Alert.vue'
import Comfirm from '../components/popup/Comfirm.vue'

const vfmBasePopup = inject('vfmBasePopup')

const router = useRouter()
const store = useStore()
const { t } = useI18n()

let loginId = ref('')
const refLoginId = ref(null);

let loginPw = ref('')
const refLoingPw = ref(null);


onMounted(() => {

})

async function btnLogin() {
    if ((loginId.value || '') == '') {
        await vfmBasePopup(Alert, { state: 'info', contents: 'MSG.CHK_USER_ID' })
        refLoginId.value.focus()
        return
    }
    if ((loginPw.value || '') == '') {
        await vfmBasePopup(Alert, { state: 'info', contents: 'MSG.CHK_USER_PW' })
        refLoingPw.value.focus()
        return
    }

    store.dispatch("NGCore/login", { loginId: loginId.value, loginPw: loginPw.value }).then(req => {
        if (req.data.status == false) {
            alert(t(req.data.errorcode))
        } else {
            store.state.NGCore.ngCoreReadyYn = 'N'      // 로그인 후 초기화 부분 다시 실행하기 위하여 'N' 처리
            //ng_core.common.isNGCoreReady = 'N'       
            router.replace('/')
        }
    }).catch(err => {
        console.log('btnLogin')
        console.dir(err)
    })
}

async function openModalTest() {
    let data = {
        state: 'info',
        contents: 'alert test',
        width: '500px',
        height: '100px',
        drag: true,
    }
    //let rtn = await vfmBasePopup(Alert, data)
    let rtn = await vfmBasePopup(Comfirm, data)
    console.log(rtn)
}

</script>

<template>
    <button @click="reveal">Hide Logo</button>
    <button @click="openModalTest">Open Modal</button>

    <div class="container">

        <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                        <div class="d-flex justify-content-center py-4">
                            <a href="../../index.html" class="logo d-flex align-items-center w-auto">
                                <img id="profile-img" src="../assets/hs_logo.png" class="profile-img-card" />
                                <!-- <span class="d-none d-lg-block">COMPANY NAME</span> -->
                            </a>
                        </div><!-- End Logo -->

                        <div class="card mb-3">

                            <div class="card-body">

                                <div class="pt-4 pb-2">
                                    <h5 class="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                    <p class="text-center small">Enter your username & password to login</p>
                                </div>

                                <form class="row g-3 needs-validation" novalidate>

                                    <div class="col-12">
                                        <label for="yourUsername" class="form-label">{{ t('LABEL.USER_ID') }}</label>
                                        <input type="text" name="username" class="form-control" ref="refLoginId"
                                            v-model="loginId" required @keyup.enter="btnLogin">
                                        <div class="invalid-feedback">Please enter your username.</div>
                                    </div>

                                    <div class="col-12">
                                        <label for="yourPassword" class="form-label">{{ t('LABEL.USER_PW') }}</label>
                                        <input type="password" name="password" class="form-control" ref="refLoingPw"
                                            v-model="loginPw" required @keyup.enter="btnLogin">
                                        <div class="invalid-feedback">Please enter your password!</div>
                                    </div>
                                    <div class="col-12">
                                        <button class="btn btn-primary w-100" type="button"
                                            @click="btnLogin">Login</button>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    </div>
</template>




<style>
.profile-img-card {
    width: 300px;
    /* height: 180px; */
    margin: auto;
    display: block;
}
</style>