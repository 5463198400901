<script setup>
import { ref, getCurrentInstance, onMounted, nextTick, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'
import Comfirm from '@/components//popup/Comfirm.vue'
import DataSelectPopup from '@/components/popup/DataSelectPopup.vue'

import { useStore } from "vuex";

const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
// const store = useStore()

let con = getCurrentInstance()
let search = {}

// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for (let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for (let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

const THEME_MST = ref()
const THEME_DTL = ref()

let grids = {
    'THEME_MST': THEME_MST,
    'THEME_DTL': THEME_DTL,

}

onMounted(() => {
    console.log('==== 컨포넌트 리스트 ====')
    let tmpGridKeys = Object.keys(ng_core.common.currentPageInfo)

    for(let i = 0; i < tmpGridKeys.length; i++) {
        console.log('** ' + tmpGridKeys[i] /**+ ' : ' + t('COMPONENT.' + tmpGridKeys[i])*/)
        let tmpSearchKeys = Object.keys(search[tmpGridKeys[i]])
        let tmpSearchColumn = ' -- Search : { '
        for(let j = 0; j < tmpSearchKeys.length; j++) {
            tmpSearchColumn += tmpSearchKeys[j] + ' '
        }
        tmpSearchColumn += '}'
        
        console.log(tmpSearchColumn)
    }
})

/*************************************************
 * Grid 관련 : Start
 *************************************************/

/** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])
}

/** 행추가 */
function onAddRow(compName) {
    grids[compName].value.grdAddRow()
}

/** 행 삭제 */
function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

/** 저장 */
function onSave(compName) {
    grids[compName].value.grdSave()
}
/** 라디오버튼 */
function onRowClickRadio(ui, row) {
    search.THEME_DTL.THEME_CODE.value = row.THEME_CODE
    onSearch('THEME_DTL')
}

// /** 셀 버튼 이벤트 */
async function onGrdCellButton(sender, ui, event) {

}
function onGrd02InitFinish(sender, ui, event) {

}
/*************************************************
 * Grid 관련 : End
 *************************************************/

/*************************************************
 * Tab 관련 : Start
*************************************************/
let tabIndex = ref(0)
// let tabGrid = [
//     ['THEME_MST'],
//     ['THEME_DTL'],
// ]

/** 탭 이동 */
function activateTab(a, b, c) {
    // 탭 페이지 리사이징
    tabPageContentResize()
}

/** 탭 리사이즈 */
function tabPageContentResize() {
    window.requestAnimationFrame(function () {
        let tmpGridKeys = Object.keys(grids)
        for (let i = 0; i < tmpGridKeys.length; i++) {
            // grids[tmpGridKeys[i]].value.refresh()
        }
    });
}
/*************************************************
 * Tab 관련 : End
 *************************************************/
</script>
<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal @resize="doResize"
                :dbl-click-splitter="false">
                <!-- 상단 : Start -->
                <Pane>
                    <!-- 검색 조건 : Start -->
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="parentCon">
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{ t('THEME_CODE') }}</label>
                                <input type="text" class="conInputText" v-model="search['THEME_MST'].THEME_CODE.value" :placeholder="t('THEME_CODE')" 
                                @keyup.enter="onSearch(['THEME_MST'])" :state="null">
                            </div>
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{ t('THEME_NAME') }}</label>
                                <input type="text" class="conInputText" v-model="search['THEME_MST'].NAME.value" :placeholder="t('NAME')" 
                                @keyup.enter="onSearch(['THEME_MST'])" :state="null">
                            </div>
                            <div class="rightCon">
                                <button ref="read" @click="onSearch('THEME_MST')" :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008')}}</button>
                                <button ref="plus" @click="onAddRow('THEME_MST')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                <button ref="minus" @click="onRemoveRow('THEME_MST')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                <button ref="save" @click="onSave('THEME_MST')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003')}}</button>
                            </div>
                        </div>
                    </div>
                    <!-- 검색 조건 : End -->
                    <!-- 그리드 영역 : Start -->
                    <div class="resultGrid" style="height:calc(100% - 40px);">
                        <BaseGrid ref="THEME_MST" :refCode="'THEME_MST'"
                         @RowClickRadio="onRowClickRadio" />
                    </div>
                    <!-- 그리드 영역 : End -->
                </Pane>
                <!-- 상단 : End   -->
                <!-- 하단 : Start -->
                <Pane>
                    <!-- Tabs : Start -->
                        <!-- Tab Page 01 : Start-->
                            <!-- 검색 조건 : Start -->
                            <div style="width:100%; height:40px; overflow:auto;">
                                 <div class="parentCon">
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{ $t('THEME_CODE') }}</label>
                                        <input type="text" class="conInputText" v-model="search['THEME_DTL'].THEME_CODE.value" :placeholder="$t('THEME_CODE')" 
                                            @keyup.enter="onSearch('THEME_DTL')" :state="null">
                                    </div>
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{ $t('THEME_NAME') }}</label>
                                        <input type="text" class="conInputText" v-model="search['THEME_DTL'].NAME.value" :placeholder="$t('NAME')" 
                                            @keyup.enter="onSearch('THEME_DTL')" :state="null">
                                    </div>
                                    <div class="rightCon">
                                        <button ref="read" @click="onSearch('THEME_DTL')" :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008')}}</button>
                                        <button ref="plus" @click="onAddRow('THEME_DTL')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('THEME_DTL')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('THEME_DTL')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003')}}</button>
                                    </div>
                                </div>
                            </div>
                            <!-- 검색 조건 : End -->
                            <!-- 그리드 영역 : Start -->
                            <div class="resultGrid" style="height:calc(100% - 40px);">
                                <BaseGrid ref="THEME_DTL" :refCode="'THEME_DTL'" />
                            </div>
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>