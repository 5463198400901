<script setup>
import { ref, getCurrentInstance, onMounted, inject } from 'vue'
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'

const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
const store = useStore()

let con = getCurrentInstance()
let search = {}

// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for (let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for (let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

const DATA_MST = ref()
const DATA_DTL_ARGS = ref()
const DATA_DTL_VIEW = ref()
const COMPONENT_MST = ref()

let grids = {
    'DATA_MST' : DATA_MST,
    'DATA_DTL_ARGS' : DATA_DTL_ARGS,
    'DATA_DTL_VIEW' : DATA_DTL_VIEW,
    'COMPONENT_MST' : COMPONENT_MST,
}
onMounted(() => {
    console.log('==== 컨포넌트 리스트 ====')
    let tmpGridKeys = Object.keys(ng_core.common.currentPageInfo)
    for (let i = 0; i < tmpGridKeys.length; i++) {
        console.log(tmpGridKeys[i] /**+ ' : ' + t('COMPONENT.' + tmpGridKeys[i])*/)
    }
})

/*************************************************
 * Grid 관련 : Start
 *************************************************/
/** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])
}

/** 행추가 */
function onAddRow(compName) {
    grids[compName].value.grdAddRow()
}

/** 행 삭제 */
function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

/** 저장 */
function onSave(compName) {
    grids[compName].value.grdSave()
}

/** 라디오버튼 */
function onRowClickRadio(ui, row) {
    search.DATA_DTL_ARGS.DATA_CODE.value = row.DATA_CODE
    onSearch('DATA_DTL_ARGS')
}

// /** 셀 버튼 이벤트 */
async function onGrdCellButton(sender, ui, event) {

}
function onGrd02InitFinish(sender, ui, event) {

}

/** 인자 가져오기 */
function onGetArgs() {
    ng_core.api.baseCoreApi.getArgs('SYS', grids['DATA_MST'].value.getRowSelectedData().SP_NAME).then(rtn => {
        if(rtn.data.status) {
            let rtndata = rtn.data.rtndata
            grids['DATA_DTL_ARGS'].value.showloadelement()

            // 현재 리스트를 가져옴
            var oldrows = grids['DATA_DTL_ARGS'].value.getrows()
            var tmp_rtndata = rtndata.filter(row => {
                // 받아온 데이터 확인
                try {
                    var tmp = oldrows.filter(row_sub => {
                        // 기존 데이터를 가져와 받아온 데이터와 비교 처리
                        if(row['PARAMETER_NAME2'] === row_sub['ARGUMENT']) {
                            return true     // 최종 제외 대상
                        } else {
                            return false
                        }
                    })
                    // 대상이 발견되면 최종 대상으로 처리
                    if(tmp.length >= 1) return false
                    return true

                } catch(err) {
                    console.log('onGetArgs')
                    console.dir(err)
                }
            })

            // 추가 대상을 넣음
            var notInArray = ['RUN_USER_ID', 'RETURN_FLAG', 'RETURN_MSG_CODE', 'RETURN_ERR_MSG']
            tmp_rtndata.map(row => {
                try {
                    var tmp_data = {
                        DATA_CODE: self.$refs.DATA_MST.rowRadioSelect_Data.DATA_CODE,
                        SEQ: '',
                        ARGUMENT: row['PARAMETER_NAME2'],
                        VAL: '',

                    }
                    var oldrows = grids['DATA_DTL_ARGS'].value.grdAddRow(tmp_data, 'last')

                } catch(error) {
                    console.log('onGetArgs2')
                    console.dir(error)
                }
            })

            grids['DATA_DTL_ARGS'].value.hideloadelement()
        } else {
            alert('?????')
        }

    }).catch(error => {
        // 처리 중 오류 발생
        vfmBasePopup(Alert, { state: 'info', contents: t('ERRORMSG.ERRORMSG0009') })

        grids['DATA_DTL_ARGS'].value.hideloadelement()
        let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
        store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })
    })
}

let dataDtlViewData = null      // 결과 정보 백업

/** 결과 가져오기 */
function onGetDataView() {
    try {
        grids['DATA_DTL_VIEW'].value.destroy()
    } catch { }
    
    var params = {
        METHOD_CODE: grids['DATA_MST'].value.getRowSelectedData().SP_NAME,
        paramsdata: {},
    }
    grids['DATA_DTL_ARGS'].value.getrows().map((row) => {
        params.paramsdata[row.ARGUMENT] = row.VAL
    })

    store.dispatch('NGCore/getSysData', params).then(resdata => {
        let rtndata = resdata.rtndata;
        if(rtndata.length === 0) {
            vfmBasePopup(Alert, { state: 'error', contents: t('MSG.MSG0014') })
            return
        }

        dataDtlViewData = rtndata       // 결과 백업
        // Grid를 그리기 위한 기본 정보 리스트
        var baseComponentData = {
            /** 화면에 사용되는 컨포넌트 리스트 */
            MENU_APPLY_COMPONENT_MST: [],
            /** 해당 컨포넌트에 사용되는 속성 리스트 */
            MENU_APPLY_COMPONENT_DTL_PROP: [],
            /** 해당 컨포넌트에 사용되는 옵션 리스트 */
            MENU_APPLY_COMPONENT_DTL_OPTION: [],
            /** 해당 컨포넌트에 사용되는 헤더 리스트 */
            MENU_APPLY_COMPONENT_DTL_HEADER: [],
            /** 해당 컨포넌트에 사용되는 컬럼 리스트 */
            MENU_APPLY_COMPONENT_DTL_COLUMN: [],
            /** 검색 인자 **/
            ARGS: [],

        }
        
        // MENU_APPLY_COMPONENT_DTL_PROP (현재 사용되지 않으므로 추후에 구현)

        // MENU_APPLY_COMPONENT_DTL_OPTION (해당 그리드에서는 필요 없음)

        // MENU_APPLY_COMPONENT_DTL_HEADER, MENU_APPLY_COMPONENT_DTL_COLUMN(두가지 동일하므로 같이 구현)
        var tmp_headers = {}
        baseComponentData['COMPONENT_TYPE'] = 'GRID'

        var tmp_keys = Object.keys(rtndata[0])
        tmp_keys.map((k, idx) => {
            baseComponentData.MENU_APPLY_COMPONENT_DTL_HEADER.push({
                COLUMN_CODE: k,
                COLUMN_NAME: k,
                FIELD_CODE: k,
                COLUMN_NAME_HEADER: k,
                SEQ: idx,
                PARENT_SEQ: '',
                HIDDEN_YN: 'N',
                EDIT_YN: 'N',
                COLUMN_TYPE: 'string',
            })
            baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN.push({
                COLUMN_CODE: k,
                COLUMN_NAME: k,
                FIELD_CODE: k,
                COLUMN_NAME_HEADER: k,
                SEQ: idx,
                //PARENT_SEQ: '',
                HIDDEN_YN: 'N',
                EDIT_YN: 'N',
                COLUMN_TYPE: 'string',
                WIDTH: 100
            })
        })

        grids['DATA_DTL_VIEW'].value.GridbaseInit(baseComponentData, baseComponentData['COMPONENT_TYPE'])
    }).catch((error) => {
        // 처리 중 오류 발생
        vfmBasePopup(Alert, { state: 'info', contents: t('ERRORMSG.ERRORMSG0009') })

        let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
        store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })
    })


}

function onInitFinish() {
    grids['DATA_DTL_VIEW'].value.grdLocalDataBinding(dataDtlViewData)
}

/*************************************************
 * Grid 관련 : End
 *************************************************/

/*************************************************
 * Tab 관련 : Start
*************************************************/
/** 탭 이동 */
function activateTab(a, b, c) {
    // 탭 페이지 리사이징
    tabPageContentResize()
}

/** 탭 리사이즈 */
function tabPageContentResize() {
    window.requestAnimationFrame(function () {
        let tmpGridKeys = Object.keys(grids)
        for (let i = 0; i < tmpGridKeys.length; i++) {
            //grids[tmpGridKeys[i]].value.refresh()
        }
    });
}
/*************************************************
 * Tab 관련 : End
 *************************************************/
</script>
<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal @resize="doResize"
                :dbl-click-splitter="false">
                <!-- 상단 : Start -->
                <Pane>
                    <!-- 검색 조건 : Start -->
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="parentCon">
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{ t('DATA_CODE') }}</label>
                                <input type="text" class="conInputText" v-model="search['DATA_MST'].DATA_CODE.value" :placeholder="t('DATA_CODE')" 
                                @keyup.enter="onSearch('DATA_MST')" :state="null">
                            </div>
                            <div class="rightCon">
                                <button ref="read" @click="onSearch('DATA_MST')" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                <button ref="plus" @click="onAddRow('DATA_MST')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                <button ref="minus" @click="onRemoveRow('DATA_MST')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                <button ref="save" @click="onSave('DATA_MST')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                            </div>
                        </div>
                    </div>
                    <!-- 검색 조건 : End -->
                    <!-- 그리드 영역 : Start -->
                    <div class="resultGrid" style="height:calc(100% - 40px);">
                        <BaseGrid ref="DATA_MST" :refCode="'DATA_MST'" @RowClickRadio="onRowClickRadio" />
                    </div>
                </Pane>
                <Pane>
                    <Splitpanes :orientation="'horizontal'" @resize="doResize">
                        <Pane>
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="parentCon">

                                    <div class="rightCon">
                                        <button @click="onGetArgs()" :title="$t('GET_ARGS')">{{t('LABEL.GET_ARGS')}}</button>
                                        <button ref="save" @click="onSave('DATA_DTL_ARGS')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                                        <button @click="onGetDataView()" :title="t('CHECK_RESULT')">{{t('CHECK_RESULT')}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 40px);">
                                <BaseGrid ref="DATA_DTL_ARGS" :refCode="'DATA_DTL_ARGS'" @RowClickRadio="onRowClickRadio" />
                            </div>
                        </Pane>
                        <Pane>
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="parentCon">
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 40px);">
                                <BaseGrid ref='DATA_DTL_VIEW' @InitFinish="onInitFinish" />
                            </div>
                        </Pane>
                    </Splitpanes>
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>