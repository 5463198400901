<script setup>
import { ref, getCurrentInstance, onMounted, nextTick, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'
import Comfirm from '@/components//popup/Comfirm.vue'
import DataSelectPopup from '@/components/popup/DataSelectPopup.vue'

import { useStore } from "vuex";

const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
const store = useStore()

let con = getCurrentInstance()
let search = {}
// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for (let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for (let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

const COMPONENT_MST = ref()
const COMPONENT_DTL_PROP = ref()
const COMPONENT_DTL_OPTION = ref()
const COMPONENT_DTL_COLUMN = ref()
const COMPONENT_DTL_HEADER = ref()
const COMPONENT_DTL_COLUMN_WO_HEADER = ref()
const DATA_MST_POPUP = ref()
const MENU_USER = ref()
let grids = {
    'COMPONENT_MST': COMPONENT_MST,
    'COMPONENT_DTL_PROP': COMPONENT_DTL_PROP,
    'COMPONENT_DTL_OPTION': COMPONENT_DTL_OPTION,
    'COMPONENT_DTL_COLUMN': COMPONENT_DTL_COLUMN,
    'COMPONENT_DTL_HEADER': COMPONENT_DTL_HEADER,
    'COMPONENT_DTL_COLUMN_WO_HEADER': COMPONENT_DTL_COLUMN_WO_HEADER,
}

onMounted(() => {
    console.log('==== 컨포넌트 리스트 ====')
    let tmpGridKeys = Object.keys(ng_core.common.currentPageInfo)

    for(let i = 0; i < tmpGridKeys.length; i++) {
        console.log('** ' + tmpGridKeys[i] /**+ ' : ' + t('COMPONENT.' + tmpGridKeys[i])*/)
        let tmpSearchKeys = Object.keys(search[tmpGridKeys[i]])
        let tmpSearchColumn = ' -- Search : { '
        for(let j = 0; j < tmpSearchKeys.length; j++) {
            tmpSearchColumn += tmpSearchKeys[j] + ' '
        }
        tmpSearchColumn += '}'
        
        console.log(tmpSearchColumn)
    }
    
})
/*************************************************
 * Grid 관련 : Start
 *************************************************/
/** 조회 */
function onSearch(compName) {
    for(let i = 0; i < compName.length; i++) {
        grids[compName[i]].value.grdSearchRef(search[compName[i]])
    }
}

/** 행추가 */
function onAddRow(compName) {
    let tmpAddData = {}
    if(compName != 'COMPONENT_MST') {
        let tmpData = grids['COMPONENT_MST'].value.getRowSelectedData()
        tmpAddData['COMPONENT_CODE'] = tmpData['COMPONENT_CODE']
        tmpAddData['COMPONENT_TYPE'] = tmpData['COMPONENT_TYPE']
    }
    grids[compName].value.grdAddRow(tmpAddData)
    // if(compName == 'COMPONENT_DTL_HEADER') {
    //     grids[compName].value.grdAddRowChild(tmpAddData)
    // } else {
    //     grids[compName].value.grdAddRow(tmpAddData)
    // }
    
}

/** 행 삭제 */
function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

/** 저장 */
function onSave(compName) {
    grids[compName].value.grdSave()
}

/** 행 선택 라디오 */
function onRowClickRadio(ui, row) {
    for(let i = 0; i < tabGrid.length; i++) {
        for(let j = 0; j < tabGrid[i].length; j++) {
            let tmpGrdNm = tabGrid[i][j]
            search[tmpGrdNm].COMPONENT_CODE.value = row.COMPONENT_CODE
            grids[tmpGrdNm].value.grdSearchRef(search[tmpGrdNm])
        }
    }
}

/** 셀 버튼 이벤트 */
async function onGrdCellButton(sender, ui, event) {
    // search.COMMON_CODE_DTL.GROUP_CODE.value = row.GROUP_CODE
    // onSearch('COMPONENT_DTL_PROP')
}
/*************************************************
 * Grid 관련 : End
 *************************************************/

/*************************************************
 * Tab 관련 : Start
*************************************************/
let tabIndex = ref(0)
let tabGrid = [
    ['COMPONENT_DTL_PROP'],
    ['COMPONENT_DTL_OPTION'],
    ['COMPONENT_DTL_COLUMN'],
    ['COMPONENT_DTL_HEADER', 'COMPONENT_DTL_COLUMN_WO_HEADER'],
]
/** 탭 이동 */
function activateTab(oldIndex, newIndex, sender) {
    // 탭 페이지 리사이징
    tabPageContentResize()
}

/** 탭 리사이즈 */
function tabPageContentResize() {
    window.requestAnimationFrame(function () {
        let tmpGridKeys = Object.keys(grids)
        for(let i = 0; i < tmpGridKeys.length; i++) {
            grids[tmpGridKeys[i]].value.refresh()
        }
    });
}
/*************************************************
 * Tab 관련 : End
 *************************************************/

/** 컬럼 불러오기 */
async function onLoadCloumn() {
    grids['COMPONENT_DTL_COLUMN'].value.showloadelement()

    if((search['COMPONENT_DTL_COLUMN'].COMPONENT_CODE.value || '') == '') {
        // 컨포넌트를 먼저 선택하세요.
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.CHKCOMPONENTCODE') })
        return 
    }

    let tmpSelectedRowData = grids['COMPONENT_MST'].value.getRowSelectedData()
    ng_core.api.baseCoreApi.getComponentColumn(tmpSelectedRowData.SYSTEM_TYPE, tmpSelectedRowData.COMPONENT_CODE).then(rtn => {
        if(rtn.data.status) {
            console.log(grids)
            let rtndata = rtn.data.rtndata

            grids['COMPONENT_DTL_COLUMN'].value.beginupdate()

            // 현재 리스트를 가져옴
            var oldrows = grids['COMPONENT_DTL_COLUMN'].value.getrows()
            var tmp_rtndata = rtndata.filter(row => {
                // 받아온 데이터 확인

                try {
                    var tmp = oldrows.filter(row_sub => {
                        // 기존 데이터를 가져와 받아온 데이터와 비교 처리
                        if(row['COMPONENT_CODE'] === row_sub['COMPONENT_CODE'] 
                        && row['COLUMN_CODE'] === row_sub['COLUMN_CODE'] 
                        && row['FIELD_CODE'] === row_sub['FIELD_CODE']) {
                            return true     // 최종 제외 대상
                        } else {
                            return false
                        }
                    })
                    // 대상이 발견되면 최종 대상으로 처리
                    if(tmp.length >= 1) return false
                    return true

                } catch(err) {
                    console.log('getComponentColumn')
                    console.dir(err)
                }
            })

            // 추가 대상을 넣음
            tmp_rtndata.map(row => {
                grids['COMPONENT_DTL_COLUMN'].value.grdAddRow(row, 'last')
            })
            grids['COMPONENT_DTL_COLUMN'].value.endupdate()
        } else {
            alert('???')
        }
        grids['COMPONENT_DTL_COLUMN'].value.hideloadelement()

    }).catch(err => {
        // 처리 중 오류 발생
        vfmBasePopup(Alert, { state: 'info', contents: t('ERRORMSG.ERRORMSG0009') })

        grids['COMPONENT_DTL_COLUMN'].value.hideloadelement()
        let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
        store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })
    })
}

/** 대상을 왼쪽으로 이동 */
async function onLeftMove() {
    let tmpDataList = grids['COMPONENT_DTL_COLUMN_WO_HEADER'].value.getGrdCheckList()
    if(tmpDataList.length == 0) {
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0013') })
        return 
    }
    let rtnComfirm = await vfmBasePopup(Comfirm, { state: 'info', contents: t('MSG.CHK_ADD') })
    if(rtnComfirm.isCanceled) return

    tmpDataList.map(function(row) {
        var tmp_row = Object.assign({}, row, {
            ROWRADIO: 'N',
            METHOD: 'A',
            ROWCHECK: 'N',
            COLUMN_SEQ: row.SEQ

        })
        // 상위 정보를 같이 넘겨야 함****
        //self.$refs.COMPONENT_DTL_HEADER.grdAddRowChild(tmp_row)       
        // treegrid 상위 처리 관계 없이 끝에 row추가
        grids['COMPONENT_DTL_HEADER'].value.grdAddRow(tmp_row, 'last')
    })

    await grids['COMPONENT_DTL_HEADER'].value.grdSave({ hideComfirm: 'Y'/**, hideMessageYN: 'Y', reloadYn: 'N' */ })

    //grids['COMPONENT_DTL_HEADER'].value.grdSearchRef(null, null, { hideMessageYN: 'Y' })
    grids['COMPONENT_DTL_COLUMN_WO_HEADER'].value.grdSearchRef(null, null, { hideMessageYN: 'Y' })
}

/** 대상을 오른쪽으로 이동 */
async function onRightMove() {
    let tmpDataList = grids['COMPONENT_DTL_HEADER'].value.getGrdCheckList()
    if(tmpDataList.length == 0) {
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0013') })
        return 
    }

    let rtn = await grids['COMPONENT_DTL_HEADER'].value.grdDeleteWithDB()
    if(rtn) {
        grids['COMPONENT_DTL_HEADER'].value.grdSearchRef()
        grids['COMPONENT_DTL_COLUMN_WO_HEADER'].value.grdSearchRef()
    } else {
        alert('????')
    }
}
</script>
<template>
    <div class="container-fluid">

        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal @resize="doResize"
                :dbl-click-splitter="false">
                <!-- 상단 : Start -->
                <Pane>
                    <!-- 검색 조건 : Start -->
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="parentCon">
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('COMPONENT_CODE')}}</label>
                                <input type="text" class="conInputText" v-model="search['COMPONENT_MST'].COMPONENT_CODE.value" :placeholder="t('COMPONENT_CODE')"
                                        @keyup.enter="onSearch(['COMPONENT_MST'])" :state="null">
                            </div>
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('COMPONENT_TYPE')}}</label>
                                <input type="text" class="conInputText" v-model="search['COMPONENT_MST'].COMPONENT_TYPE.value" :placeholder="t('COMPONENT_TYPE')"
                                        @keyup.enter="onSearch(['COMPONENT_MST'])" :state="null">
                            </div>
                            <div class="rightCon">
                                <button ref="read01" @click="onSearch(['COMPONENT_MST'])" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                <button ref="plus01" @click="onAddRow('COMPONENT_MST')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                <button ref="minus01" @click="onRemoveRow('COMPONENT_MST')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                <button ref="save01" @click="onSave('COMPONENT_MST')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                            </div>

                        </div>
                    </div>
                    <!-- 검색 조건 : End -->
                    <!-- 그리드 영역 : Start -->
                    <div class="resultGrid" style="height:calc(100% - 40px);">
                        <BaseGrid ref="COMPONENT_MST" :refCode="'COMPONENT_MST'"
                            @RowClickRadio="onRowClickRadio" />
                    </div>
                    <!-- 그리드 영역 : End -->
                </Pane>
                <!-- 상단 : End   -->
                <!-- 하단 : Start -->
                <Pane>
                    <!-- Tabs : Start -->
                    <b-tabs ref="bottomTabs" small class="h-100" @activate-tab="activateTab" v-model="tabIndex" style="height:calc(100% - 40px);">
                        <!-- Tab Page 01 : Start-->
                        <b-tab :title="t('LABEL.ATTR_MANAGE')" id="COMPONENT_DTL_PROP" active>
                            <!-- 검색 조건 : Start -->
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="parentCon">
                                    <div class="rightCon">
                                        <button ref="read02" @click="onSearch(['COMPONENT_DTL_PROP'])" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                        <button ref="plus02" @click="onAddRow('COMPONENT_DTL_PROP')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus02" @click="onRemoveRow('COMPONENT_DTL_PROP')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save02" @click="onSave('COMPONENT_DTL_PROP')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                                    </div>
                                </div>
                            </div>
                            <!-- 검색 조건 : End -->
                            <!-- 그리드 영역 : Start -->
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref="COMPONENT_DTL_PROP" :refCode="'COMPONENT_DTL_PROP'"
                                    @RowClickRadio="onRowClickRadio" />
                            </div>
                            <!-- 그리드 영역 : End -->
                        </b-tab>
                        <!-- Tab Page 01 : End  -->
                        <!-- Tab Page 02 : Start-->
                        <b-tab :title="t('LABEL.OPTION_MANAGE')" >
                            <!-- 검색 조건 : Start -->
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="parentCon">
                                    <div class="rightCon">
                                        <button ref="read03" @click="onSearch(['COMPONENT_DTL_OPTION'])" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                        <button ref="plus03" @click="onAddRow('COMPONENT_DTL_OPTION')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus03" @click="onRemoveRow('COMPONENT_DTL_OPTION')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save03" @click="onSave('COMPONENT_DTL_OPTION')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                                    </div>
                                </div>

                            </div>
                            <!-- 검색 조건 : End -->
                            <!-- 그리드 영역 : Start -->
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref="COMPONENT_DTL_OPTION" :refCode="'COMPONENT_DTL_OPTION'"
                                    @RowClickRadio="onRowClickRadio" />
                            </div>
                            <!-- 그리드 영역 : End -->
                        </b-tab>
                        <!-- Tab Page 02 : End  -->
                        <!-- Tab Page 03 : Start-->
                        <b-tab :title="t('LABEL.COLUMN_MANAGE')" >
                            <!-- 검색 조건 : Start -->
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="parentCon">
                                    <div class="rightCon">
                                        <button ref="loadColumn" @click="onLoadCloumn()" :title="t('LOAD_COLUMN')">{{t('LOAD_COLUMN')}}</button>
                                        <button ref="read04" @click="onSearch(['COMPONENT_DTL_COLUMN'])" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                        <button ref="plus04" @click="onAddRow('COMPONENT_DTL_COLUMN')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus04" @click="onRemoveRow('COMPONENT_DTL_COLUMN')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save04" @click="onSave('COMPONENT_DTL_COLUMN')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                                    </div>
                                </div>
                            </div>
                            <!-- 검색 조건 : End -->
                            <!-- 그리드 영역 : Start -->
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref="COMPONENT_DTL_COLUMN" :refCode="'COMPONENT_DTL_COLUMN'"
                                    @RowClickRadio="onRowClickRadio" />
                            </div>
                            <!-- 그리드 영역 : End -->
                        </b-tab>
                        <!-- Tab Page 03 : End  -->
                        <!-- Tab Page 04 : Start-->
                        <b-tab :title="t('LABEL.HEADER_MANAGE')" >
                            <Splitpanes @resize="doResize">
                                <Pane>
                                    <div style="width:100%; height:40px; overflow:auto;">
                                        <div class="parentCon">
                                            <div class="rightCon">
                                                <button ref="read05" @click="onSearch(['COMPONENT_DTL_HEADER', 'COMPONENT_DTL_COLUMN_WO_HEADER'])" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                                <button ref="plus05" @click="onAddRow('COMPONENT_DTL_HEADER')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                                <button ref="minus05" @click="onRemoveRow('COMPONENT_DTL_HEADER')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                                <button ref="save05" @click="onSave('COMPONENT_DTL_HEADER')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                                                <button @click="onLeftMove()" :title="t('BUTTON0014')"><font-awesome-icon icon="angle-left" /></button>
                                                <button @click="onRightMove()" :title="t('BUTTON0015')"><font-awesome-icon icon="angle-right" /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="resultGrid" style="height:calc(100% - 80px);">
                                        <BaseGrid ref="COMPONENT_DTL_HEADER" :refCode="'COMPONENT_DTL_HEADER'"
                                            @RowClickRadio="onRowClickRadio" />
                                    </div>
                                </Pane>
                                <Pane>
                                    <div style="width:100%; height:40px; overflow:auto;">
                                        <div class="parentCon">
                                        </div>
                                    </div>
                                    <div class="resultGrid" style="height:calc(100% - 80px);">
                                        <BaseGrid ref="COMPONENT_DTL_COLUMN_WO_HEADER" :refCode="'COMPONENT_DTL_COLUMN_WO_HEADER'"
                                            @RowClickRadio="onRowClickRadio" />
                                    </div>
                                </Pane>
                            </Splitpanes>   
                        </b-tab>
                        <!-- Tab Page 04 : End  -->
                    </b-tabs>
                    <!-- Tabs : End   -->
                </Pane>
                <!-- 하단 : End   -->
            </Splitpanes>
        </div>
    </div>
</template>
<style>

</style>