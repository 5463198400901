<script setup>
import { ref } from 'vue'
import { defineProps, defineEmits, onMounted, computed, nextTick, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'
import { $vfm } from 'vue-final-modal'

const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
const props = defineProps(['modalName', 'title', 'popupSize', 'grid', 'componentList', 'targetREFCODEList', 'width', 'height', 'api'])
const emit = defineEmits(['confirm', 'cancel'])

// 사이즈
let modalSize = computed(() => {
    let styles = {
        //width: (props.width || '') == '' ? '0px' : props.width,
        //height: (props.height || '') == '' ? '0px' : props.height,
    }
    if((props.width || '') != '') styles['width'] = props.width
    if((props.height || '') != '') styles['height'] = props.height
    
    return styles
})

let search = {}
// 검색 조건 생성
let tmpkeys = [props.grid]
for (let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for (let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

const grid = ref()
let grids = {}
onMounted(() => {
    grids[props.grid] = grid
    setTimeout(() => {
        onSearch(props.grid)
    }, 200);
});

/** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])
}

/** OK 버튼 */
function onConfirm(close) {
    let tmpSctData = grid.value.getGrdCheckList(); //grid.value.getRowSelectedData()
    if(!tmpSctData) {
        vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0009') })
    } else {
        emit('confirm', close, tmpSctData)
    }
}

/** 취소 버튼 */
function onCancel(close) {
    emit('cancel', close, false)
}

</script>

<template>
    <!-- Modal -->
    <vue-final-modal v-slot="{ close }" @before-open="onBeforeOpen" classes="modal-container" content-class="modal-content2" :drag="(props.drag || '') == '' ? flase : drag"
        :click-to-close="false" transition="" overlay-transition="" >
        <div class="border-bottom">
            <button class="btn-close modal__close" @click="onCancel(close)"></button>
            <span class="modal__title">{{ props.title }}</span>
        </div>
        <div class="modal__content" :style="modalSize">
            <div class="resultGrid" style="height:100%;">
                <BaseGrid ref='grid' :refCode="'COMPONENT_MST'" 
                        @rowclick="onGrdRowClick"
                        @CellDblClick="onCellDbClick"
                        />
            </div>
        </div>
        <div class="modal__action">
            <button type="button" class="btn btn-primary" @click="onConfirm(close)">{{ t('BUTTON.BUTTON0001') }}</button>
            <button type="button" class="btn btn-secondary" @click="onCancel(close)">{{ t('BUTTON.BUTTON0002') }}</button>
        </div>
    </vue-final-modal>
</template>

<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .modal-content2 {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}

.modal__title {
    margin: 0 2rem 0.5rem 0;
    font-size: 1.5rem;
    font-weight: 700;
}

.modal__content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 5px;
}

.modal__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 1rem 0 0;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}


.dark-mode div::v-deep .modal-content2 {
    border-color: #2d3748;
    background-color: #1a202c;
}
</style>