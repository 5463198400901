<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'

const { t } = useI18n()

let con = getCurrentInstance()
let search = {}
// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for(let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for(let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

const LANG = ref()

let grids = {
    'LANG': LANG,
}


/** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])

}

function onAddRow(compName) {
    grids[compName].value.grdAddRow()
}

function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

function onSave(compName) {
    grids[compName].value.grdSave()
}

</script>
<template>
    <div class="container-fluid">

        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter"
                        horizontal
                        @resize="doResize" :dbl-click-splitter="false">
                <Pane>
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="searchSplitCon parentCon">
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('LANG_TYPE')}}</label>
                                <input type="text" class="conInputText" v-model="search['LANG'].LANG_TYPE.value" :placeholder="t('LANG_TYPE')"
                                        @keyup.enter="onSearch('LANG')" :state="null">
                            </div>
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('GROUP_CODE')}}</label>
                                <input type="text" class="conInputText" v-model="search['LANG'].GROUP_CD.value" :placeholder="t('GROUP_CODE')"
                                        @keyup.enter="onSearch('LANG')" :state="null">
                            </div>
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('LANG_KEY')}}</label>
                                <input type="text" class="conInputText" v-model="search['LANG'].LANG_KEY.value" :placeholder="t('LANG_KEY')"
                                        @keyup.enter="onSearch('LANG')" :state="null">
                            </div>
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('LANG_VALUE')}}</label>
                                <input type="text" class="conInputText" v-model="search['LANG'].LANG_VALUE.value" :placeholder="t('LANG_VALUE')"
                                        @keyup.enter="onSearch('LANG')" :state="null">
                            </div>
                            <div class="rightCon">
                                <button ref="read" @click="onSearch('LANG')" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                <button ref="plus" @click="onAddRow('LANG')" :title="t('BUTTON009')"><font-awesome-icon icon="plus" /></button>
                                <button ref="minus" @click="onRemoveRow('LANG')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                <button ref="save" @click="onSave('LANG')" :title="t('BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                                
                                <GridColumnPerson @Grid="'LANG'" :Refs="$refs" :title="$t('BUTTON.BUTTON0011')" />
                                <ExportButton @Refs="$refs" Grid="'LANG'" :title="$t('BUTTON.BUTTON0012')" />
                                <ImportButton @Refs="$refs" Grid="'LANG'" :title="$t('BUTTON.BUTTON0013')" />
                            </div>
                        </div>
                    </div>
                     <div class="resultGrid" style="height:calc(100% - 40px);">
                        <BaseGrid ref='LANG' :refCode="'LANG'" @rowclick="onGrd02RowClick"/>
                    </div>
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>