<script setup>
import { ref, getCurrentInstance, onMounted, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import DataSelectPopup from '@/components/popup/DataSelectPopup.vue'

import { useStore } from "vuex";

const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
const store = useStore()

let con = getCurrentInstance()
let search = {}
// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for (let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for (let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

const MENU_ADM = ref()
const MENU_USER = ref()
const MENU_MOBILE = ref()
let grids = {
    'MENU_ADM': MENU_ADM,
    'MENU_USER': MENU_USER,
    'MENU_MOBILE': MENU_MOBILE,
}

/*************************************************
 * Grid 관련 : Start
 *************************************************/
/** 조회 */
function onSearch(compName) {
    
    search[compName].MENU_TYPE.value = compName.split('_')[1]
    grids[compName].value.grdSearchRef(search[compName])
}

let menuCodeIdx = 0
/** 행추가 */
function onAddRow(compName) {
    //grids[compName].value.grdAddRowChild({ MENU_AREA_TYPE: compName.split('_')[1], MENU_CODE: 'test' })
    grids[compName].value.grdAddRow({ MENU_AREA_TYPE: compName.split('_')[1], MENU_CODE: 'MENU_' + compName.split('_')[1] + '_' + menuCodeIdx }, 'last')
}

/** 행 삭제 */
function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

/** 저장 */
function onSave(compName) {
    grids[compName].value.grdSave()
}

/** 셀 버튼 이벤트 */
async function onGrdCellButton(sender, ui, event) {
    var gridstr = ui.rowData.MENU_TYPE;
    if (gridstr == 'PAGE' || gridstr == 'BOARD') {
        let paramsPopup = {
            modalName: 'DataBindingList',
            title: 'Data Binding List',
            width: '1000px !important',
            height: '500px !important',
            grid: gridstr,
            componentList: store.state.NGCore.currentPageInfo,
            targetREFCODEList: ['PAGE', 'BOARD'], buttons: ['ok']
        }

        vfmBasePopup(DataSelectPopup, paramsPopup).then(rtn => {
            if(rtn != false) {
                sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.VIEW_CODE)
            }
        })
        
    }
}
/*************************************************
 * Grid 관련 : End
 *************************************************/

/*************************************************
 * Tab 관련 : Start
*************************************************/
/** 탭 이동 */
function activateTab(a, b, c) {
    // 탭 페이지 리사이징
    tabPageContentResize()
}

/** 탭 리사이즈 */
function tabPageContentResize() {
    window.requestAnimationFrame(function () {
        let tmpGridKeys = Object.keys(grids)
        for(let i = 0; i < tmpGridKeys.length; i++) {
            grids[tmpGridKeys[i]].value.refresh()
        }
    });
}
/*************************************************
 * Tab 관련 : End
 *************************************************/

</script>
<template>
    <div class="container-fluid">

        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal @resize="tabPageContentResize"
                :dbl-click-splitter="false">
                <Pane>
                    <b-tabs ref="bottomTabs" small class="h-100" @activate-tab="activateTab" @resize="tabPageContentResize" >
                        <!-- content-class="mt-2" -->
                        <b-tab :title="t('ADMIN')" active>
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="searchSplitCon parentCon">
                                    <!-- 공통 클래스 사용(Style 정의[component_code]) -->
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{t('MENU_CODE')}}</label>
                                        <input type="text" class="conInputText"
                                            v-model="search['MENU_ADM'].MENU_CODE.value" :placeholder="t('MENU_CODE')"
                                            @keyup.enter="onSearch('MENU_ADM')" :state="null">
                                    </div>
                                    <!-- 공통 클래스 사용(Style 정의[component_name]) -->
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{t('PARENT_MENU_CODE')}}</label>
                                        <input type="text" class="conInputText"
                                            v-model="search['MENU_ADM'].PARENT_MENU_CODE.value"
                                            :placeholder="t('PARENT_MENU_CODE')" @keyup.enter="onSearch('MENU_ADM')"
                                            :state="null">
                                    </div>

                                    <div class="rightCon">
                                        <button ref="read" @click="onSearch('MENU_ADM')"
                                            :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                        <button ref="plus" @click="onAddRow('MENU_ADM')"
                                            :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('MENU_ADM')"
                                            :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('MENU_ADM')"
                                            :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref='MENU_ADM' :refCode="'MENU_ADM'" @CellButtonClick="onGrdCellButton" />
                            </div>
                        </b-tab>

                        <b-tab :title="t('USER')">
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="searchSplitCon parentCon">
                                    <!-- 공통 클래스 사용(Style 정의[component_code]) -->
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{t('MENU_CODE')}}</label>
                                        <input type="text" class="conInputText"
                                            v-model="search['MENU_USER'].MENU_CODE.value" :placeholder="t('MENU_CODE')"
                                            @keyup.enter="onSearch('MENU_USER')" :state="null">
                                    </div>
                                    <!-- 공통 클래스 사용(Style 정의[component_name]) -->
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{t('PARENT_MENU_CODE')}}</label>
                                        <input type="text" class="conInputText"
                                            v-model="search['MENU_USER'].PARENT_MENU_CODE.value"
                                            :placeholder="t('PARENT_MENU_CODE')" @keyup.enter="onSearch('MENU_USER')"
                                            :state="null">
                                    </div>

                                    <div class="rightCon">
                                        <button ref="read" @click="onSearch('MENU_USER')"
                                            :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                        <button ref="plus" @click="onAddRow('MENU_USER')"
                                            :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('MENU_USER')"
                                            :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('MENU_USER')"
                                            :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref='MENU_USER' :refCode="'MENU_USER'" @CellButtonClick="onGrdCellButton" />
                            </div>
                        </b-tab>

                        <b-tab :title="t('MOBILE')">
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="searchSplitCon parentCon">
                                    <!-- 공통 클래스 사용(Style 정의[component_code]) -->
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{t('MENU_CODE')}}</label>
                                        <input type="text" class="conInputText"
                                            v-model="search['MENU_MOBILE'].MENU_CODE.value" :placeholder="t('MENU_CODE')"
                                            @keyup.enter="onSearch('MENU_MOBILE')" :state="null">
                                    </div>
                                    <!-- 공통 클래스 사용(Style 정의[component_name]) -->
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{t('PARENT_MENU_CODE')}}</label>
                                        <input type="text" class="conInputText"
                                            v-model="search['MENU_MOBILE'].PARENT_MENU_CODE.value"
                                            :placeholder="t('PARENT_MENU_CODE')" @keyup.enter="onSearch('MENU_MOBILE')"
                                            :state="null">
                                    </div>

                                    <div class="rightCon">
                                        <button ref="read" @click="onSearch('MENU_MOBILE')"
                                            :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                        <button ref="plus" @click="onAddRow('MENU_MOBILE')"
                                            :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('MENU_MOBILE')"
                                            :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('MENU_MOBILE')"
                                            :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref='MENU_MOBILE' :refCode="'MENU_MOBILE'" @CellButtonClick="onGrdCellButton" />
                            </div>
                        </b-tab>                        
                    </b-tabs>
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>
<style>

</style>