<script setup>
import { ref, computed, getCurrentInstance, onMounted, inject, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from "vuex";
import BoardDetail from './popup/BoardDetail.vue'
import BoardWrite from './popup/BoardWrite.vue'

const { t } = useI18n()
const router = useRouter()
const store = useStore()
let con = getCurrentInstance()
const vfmBasePopup = inject('vfmBasePopup')

const boardname = ref('EVENT');
const currentPage = ref(1); // 현재페이지
const rows = ref(0);        // 전체행수
const perPage = ref(20);    // 페이지당 Item count
const items = ref([]);      // 게시글s

const serchArr = [
    'TABLENAME', 'PAGE_NO', 'PAGE_SIZE', 'SUBJECT', 'CONTENTS', 'NOTICE_YN', 'CATEGORY', 'COMPANY', 'IMAGE_YN'
]
let search = {};
for (let i = 0; i < serchArr.length; i++) {
    search[serchArr[i]] = ref('');
}

const baseUrl = computed(() => {
    return ng_core.common.config.baseUrl;
});

onMounted(() => {
    search.TABLENAME.value = boardname.value;
    onSearch();
});

const onSearch = () => {
    search.PAGE_NO.value = currentPage.value;
    search.PAGE_SIZE.value = perPage.value;
    let params = {}
    if (search != null) {
        var tmpkeys = Object.keys(search)
        for (let i = 0; i < tmpkeys.length; i++) {
            params[tmpkeys[i]] = search[tmpkeys[i]].value
        }
    }
    store.dispatch('NGCore/getSysData', { METHOD_CODE: 'BOARDLIST', paramsdata: params }).then((resdata) => {
        let rtndata = resdata.rtndata;
        items.value = rtndata;
        rows.value = Number(resdata.output['TOTALROWS']);
    }).catch((error) => {

    });
}

const onBoardWrite = () => {
    let paramsPopup = {
        modalName: 'Board Write',
        title: 'Board Write',
        width: '810px !important',
        height: '800px !important',
        method: "A",
        boardname: search.TABLENAME.value,
        row: null,
        buttons: ['ok']
    }

    vfmBasePopup(BoardWrite, paramsPopup).then(rtn => {
        if(rtn != false) {
            onSearch();
            // sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.VIEW_CODE)
        }
    })
}

const replaceByDefault = (e) => {
    e.target.src = baseUrl.value + "/img/noimage.png";
};

const openDetailPopup = (item) => {
    let paramsPopup = {
        modalName: 'Board Detail',
        title: item.SUBJECT,
        // width: '1000px !important',
        // height: '800px !important',
        boardname: search.TABLENAME.value,
        idx: item.IDX,
        buttons: ['ok']
    }

    vfmBasePopup(BoardDetail, paramsPopup).then(rtn => {
        if(rtn != false) {
            if(rtn == "U") {
                let paramsPopup = {
                    modalName: 'Board Write',
                    title: 'Board Write',
                    width: '810px !important',
                    height: '800px !important',
                    method: "U",
                    boardname: search.TABLENAME.value,
                    row: item,
                    buttons: ['ok']
                }
                vfmBasePopup(BoardWrite, paramsPopup).then(rtn => {
                    if(rtn != false) {
                        onSearch();
                        // sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.VIEW_CODE)
                    }
                })
            }
            // sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.VIEW_CODE)
        }
    })
}

watch([currentPage], (newValue, oldVale) => {
    onSearch();
})

</script>
<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter"
                        horizontal
                        @resize="doResize" :dbl-click-splitter="false">
            <!-- 조회 상단-->
            <Pane>
                <div style="width:100%; height:40px; overflow:auto;">
                    <div class="parentCon">
                        <div class="leftCon ml-1 mr-1">
                            <label for="input-none" class="conLabel">{{t('TABLENAME')}}</label>
                            <input type="text" class="conInputText" v-model="search.TABLENAME.value" :placeholder="t('TABLENAME')"
                                    @keyup.enter="onSearch()" :state="null">
                        </div>
                        <div class="leftCon ml-1 mr-1">
                            <label for="input-none" class="conLabel">{{t('SUBJECT')}}</label>
                            <input type="text" class="conInputText" v-model="search.SUBJECT.value" :placeholder="t('SUBJECT')"
                                    @keyup.enter="onSearch()" :state="null">
                        </div>
                        <div class="rightCon">
                            <button ref="read" @click="onSearch()" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                            <button ref="plus" @click="onBoardWrite('BOARDLIST')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                        </div>
                    </div>
                </div>
                <div class="resultGrid" style="height:calc(100% - 40px);">
                    <div class="page row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5" style="padding: 0 10px 0 10px;">
                        <div class="col" v-for="item in items" :key="item.IDX">
                            <!-- @click="goto(item.IDX)" -->
                            <b-card no-body
                                @click="openDetailPopup(item)"
                                >
                                <img :src="baseUrl + item.UPLOAD1_URL" style="height:340px; object-fit: cover;" :alt="item.SUBJECT" @error="replaceByDefault">
                                <b-card-title class="cardsubject">
                                    <small :title="item.SUBJECT">
                                        {{item.SUBJECT}}
                                    </small>
                                </b-card-title>
                            </b-card>
                        </div>
                    </div>
                    <b-pagination v-if="rows>0"
                        align="center"
                        style="padding-bottom:10px;"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                    ></b-pagination>
                </div>
            </Pane>
        </Splitpanes>
        </div>
    </div>
</template>
<style scoped>

.card {
    max-width:100% !important;
}
.sch_row {
    margin-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.sch-con-set-st {
    width: 290px;
    margin-right: 20px;
    display: flex;
}

.kd_labelWrap {
    font-size: 12px;
}

.sch-labelWrap-st {
    width: 150px;
    text-align: left;
    padding-right: 5px !important;
    padding-left: 5px !important;
    margin: 0 !important;
    font-weight: 400;
    font-size: 13px;
}

.k-textbox {
    height: 25px !important;
    /*margin-right: 5px;*/
    font-size: 12px;                        
    display: inline-block !important;
    border-radius: 0 !important;
}

.sch-kd-comp-st {
    width: 200px;
}
.cardsubject {
    height:60px;
    font-size: 1.1em;
    padding:0 5px;
    /* width:285px; */
    margin: auto;
    vertical-align: middle;

    white-space: normal;
    display:-webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
}
.col {
    padding:7px;
}
</style>