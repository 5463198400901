

export default function NoligoCore(i18n) {
    return {
        namespaced: true,
        state: () => ({
            // /** 라우터 화면 보일지 결정 */
            // isBaseView: 'N',
            isLoading: 'N',
            loadingCount: 0,
            ngCoreReadyYn: 'N',
            /** 유저 정보 */
            userInfo: {},
            menuInfo: [],
            menuFavorites: {},
            currentMenuInfo: {},
            currentPageInfo: {},
            currentPageBindingData: {},

            /** 사용할 메뉴 그룹 */
            menuAreaType: '',
            isDev: 'Y',
        }),
        mutations: {
            /** 로딩 카운트 증가 */
            addLoadingCount(state) {
                state.loadingCount++
                state.isLoading = state.loadingCount == 0 ? 'N' : 'Y'
            },
            /** 로딩 카운트 감소 */
            subLoadingCount(state) {
                state.loadingCount--
                state.isLoading = state.loadingCount == 0 ? 'N' : 'Y'
            },
            /** NG Core와 데이터 동기화 */
            syncNGCore(state) {
                state.userInfo = JSON.parse(localStorage.getItem('loginInfo'))
                state.menuInfo = ng_core.common.menu
                state.menuFavorites = ng_core.common.menuFavorites
                state.currentPageBindingData = ng_core.common.currentPageBindingData
                state.ngCoreReadyYn = 'Y'
            }
            // setIsLoading(state) {
            //     state.isLoading = state.loadingCount == 0 ? 'N' : 'Y'
            // }
            // setBaesView(state, viewYn) {
            //     state.isBaseView = viewYn
            // },
        },
        actions: {
            /** NG Core 초기화 */
            async init(state) {
                state.commit('addLoadingCount')
                try {
                    state.isDev = noligo_config.isDev
                    state.menuAreaType = noligo_config.menuAreaType

                    await ng_core.init({          // noligo core 초기화
                        isDev: 'Y',
                        noligo_fw_key: 'test',     // 노리고 프레임웍 키
                        grid_key: '',
                        baseUrl: '',                // 운영에 사용되는 URL
                    })

                    await state.dispatch('checkLogin')

                    await state.dispatch('firseData')
                } catch (err) {
                    console.log('init')
                    console.dir(err)
                } finally {
                    state.commit('subLoadingCount')
                }
            },
            /** NG Core 최초 데이터 */
            async firseData(state) {
                state.commit('addLoadingCount')

                try {
                    await ng_core.api.baseCoreApi.getFirstData(state.menuAreaType, 'BOARDSMNG')
                } catch (err) {
                    console.log('firseData')
                    console.dir(err)
                } finally {
                    state.commit('subLoadingCount')
                }
            },
            /** 로그인 */
            async login(state, loginInfo) {
                state.commit('addLoadingCount')

                try {
                    let req = await ng_core.api.loginApi.login(loginInfo.loginId, loginInfo.loginPw)
                    return req
                } catch (err) {
                    console.log('login')
                    console.dir(err)
                } finally {
                    state.commit('subLoadingCount')
                }

                // ng_core.api.loginApi.login(loginId.value, loginPw.value).then(async req => {

                //     console.dir(req.data)
                //     if(req.data.status == false) {
                //         alert(t(req.data.errorcode))
                //         return
                //     } else {
                //         // 로그인 성공
                //         // 초기 정보 재호출
                //         await store.dispatch("NGCore/init")
                //         // 메인 화면으로 이동
                //         router.push('/')
                //     }
                // }).catch(err => {

                // })            
            },
            /** 로그인 체크 */
            async checkLogin(state) {
                try {
                    await ng_core.api.loginApi.chkeckLogin()
                } catch (err) {
                    console.log('checkLogin')
                    console.dir(err)
                } finally {
                }

            },

            /** sys 불러오기 */
            async getSysData(state, paramDatas) {
                state.commit('addLoadingCount')
                var params = {
                    VIEW_CODE: ng_core.common.currentMenuInfo.MENU_CODE,
                    METHOD_CODE: paramDatas.METHOD_CODE,
                    DATA: paramDatas.paramsdata,
                }

                if (paramDatas.hasOwnProperty('SQLLOG_YN')) {
                    params['SQLLOG_YN'] = paramDatas['SQLLOG_YN']
                }

                try {
                    let rtn = await ng_core.common.getSysData(params)
                    if (rtn.data.status) {
                        // {0}건이 조회 되었습니다.
                        state.dispatch('MQ/addMessage', { state: 'info', message: i18n.global.t('MSG.MSG0024', [rtn.data.rtndata.length]) }, { root: true })
                        return rtn.data // rtn.data.rtndata (OUTPUT;)
                    } else {
                        throw rtn.data
                    }
                } catch (err) {
                    console.log('getSysData')
                    console.dir(err)
                    return err
                } finally {
                    state.commit('subLoadingCount')
                }
            },

            /** sys 저장 */
            async saveSysData(state, paramDatas) {
                state.commit('addLoadingCount')

                var params = {
                    VIEW_CODE: ng_core.common.currentMenuInfo.MENU_CODE,
                    METHOD_CODE: paramDatas.METHOD_CODE,
                    //SQLLOG_YN: 'Y',             // db log 남길지 유무
                    DATA: paramDatas.paramsdata,
                }

                if (paramDatas.hasOwnProperty('SQLLOG_YN')) {
                    params['SQLLOG_YN'] = paramDatas['SQLLOG_YN']
                }

                try {
                    let rtn = await ng_core.common.saveSysData(params)
                    if (rtn.data.status) {
                        // {0}건이 처리되었습니다.
                        state.dispatch('MQ/addMessage', { state: 'info', message: i18n.global.t('MSG.MSG0025', [rtn.data.rtncnt]) }, { root: true })
                        return rtn.data
                    } else {
                        throw rtn.data
                    }
                } catch (err) {
                    return err.response.data
                } finally {
                    state.commit('subLoadingCount')
                }
            },

            /** 일반 불러오기 */
            async getData(state, paramDatas) {
                state.commit('addLoadingCount')
                var params = {
                    PREF: paramDatas.pref,
                    VIEW_CODE: ng_core.common.currentMenuInfo.MENU_CODE,
                    METHOD_CODE: paramDatas.METHOD_CODE,
                    DATA: paramDatas.paramsdata,
                }

                if (paramDatas.hasOwnProperty('SQLLOG_YN')) {
                    params['SQLLOG_YN'] = paramDatas['SQLLOG_YN']
                }

                try {
                    let rtn = await ng_core.common.getData(params)
                    if (rtn.data.status) {
                        return rtn.data.rtndata
                    } else {
                        throw rtn.data
                    }
                } catch (err) {
                    console.log('getData')
                    console.dir(err)
                    return err
                } finally {
                    state.commit('subLoadingCount')
                }
            },

            /** sys 저장 */
            async saveData(state, paramDatas) {
                state.commit('addLoadingCount')

                var params = {
                    PREF: paramDatas.pref,
                    VIEW_CODE: ng_core.common.currentMenuInfo.MENU_CODE,
                    METHOD_CODE: paramDatas.METHOD_CODE,
                    //SQLLOG_YN: 'Y',             // db log 남길지 유무
                    DATA: paramDatas.paramsdata,
                    BATCH: paramDatas.BATCH,
                }

                if (paramDatas.hasOwnProperty('SQLLOG_YN')) {
                    params['SQLLOG_YN'] = paramDatas['SQLLOG_YN']
                }

                try {
                    let rtn = await ng_core.common.saveData(params)
                    return rtn.data
                } catch (err) {
                    console.log('saveData')
                    console.dir(err)
                    return err
                } finally {
                    state.commit('subLoadingCount')
                }
            },

        },
        getters: {
        }
    }
}
