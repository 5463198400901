<script setup>
import { ref, defineProps, defineEmits, onMounted, getCurrentInstance, inject } from 'vue'
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'
import Alert from '../../popup/Alert.vue'
import Comfirm from '../../popup/Comfirm.vue'
import pq from 'pqgrid';

import BaseGrid from '../baseGrid'
import createColModel from './createColModel'

const vfmBasePopup = inject('vfmBasePopup')     // 기본 팝업
let { t } = useI18n()
const store = useStore()
const props = defineProps(['refCode', 'cClose'])
const emit = defineEmits([
    'RowClickRadio', 'CellDblClick', 'DataReady', 'CellClick', 'CellButtonClick', 'InitFinish'
])
/* 부모에게 메소드 오픈 대상 */
defineExpose({ 
    grid, grdSearchRef, grdSearch, GridbaseInit,
    grdAddRow, grdAddRowChild, grdDeleteRow, grdRemoveRow, grdDeleteWithDB, grdSave,
    getRowSelectedIndexByRadio, getRowSelectedDataByRadio, getRowSelectedIndex, 
    getRowSelectedData, getCellSelectedColumnIndex,
    setCellData, setRowData,
    gridImport, resize, refresh,
    beginupdate, endupdate, showloadelement, hideloadelement,
    getrows, getGrdCheckList,
    destroy, grdLocalDataBinding,
})

/** 그리드 기본 정보 */
let baseGrid = {}

var grid = ref({})
var grd = ref()
let cClose                  // 부모 노드에서 팝업의 경우 close 함수를 보내줘야 정상적으로 종료 처리가 가능함(DataSelectPopup.vue 파일 참고)

/** 그리드 이벤트 메니저 */
let gridEventManager = {
    /** 데이터가 없을때 콜백 */
    beforeSaveNoData: [],
    /** 저장 직전 콜백 */
    beforeSave: [],
    /** 저장 후 콜백 */
    afterSave: [],
    beforeAdd: [],
    afterAdd: [],
    beforeRemove: [],
    afterRemove: [],
}

/** 그리드 이벤트 메니져 함수 */
function executeGridEventManager(eventNm, data) {
    if(gridEventManager[eventNm] != null && gridEventManager[eventNm].length > 0) {
        let tmpEvent = gridEventManager[eventNm]
        for(let i = 0; i< tmpEvent.length; i++) {
            tmpEvent[i](data)
        }
    }
}


let grdSearchParams = {}
let grdEditList = []
/** 체크된 리스트 */
let grdCheckList = []
function getGrdCheckList() {
    return grdCheckList
}
let grdDataOrg = []
/** 저장 대상 리스트(api호출때 사용 됨) */
let grdSaveData = []

/** 선택 행 Idx(라디오박스) */
let rowSelectedIndexByRadio = null
function getRowSelectedIndexByRadio() {
    return rowSelectedIndexByRadio
}
/** 선택 행 데이터(라디오박스) */
let rowSelectedDataByRadio = null
function getRowSelectedDataByRadio() {
    return rowSelectedDataByRadio
}
/** 선택 행 정보 */
let rowSelectedIndex = null
function getRowSelectedIndex() {
    return rowSelectedIndex
}
/** 선택 행 데이터 */
let rowSelectedData = null
function getRowSelectedData() {
    return rowSelectedData
}
/** 선택 행의 cell 컬럼 정보 */
let cellSelectedColumnIndex = null
function getCellSelectedColumnIndex() {
    return cellSelectedColumnIndex
}

let grdColumns = {}
// TREEGRID 상하 관계
let treeModel = {
    id: null,
    parentId: null
}

let {
    refCode,
    baseComponentData,
    baseBindingData,
    menuCode,
    gridType,
} = BaseGrid(props.refCode)

/** PQGrid 생성용 옵션 */
let options = {
    width: '100%',
    height: '100%',
    dataModel: { data: [] },
    locale: 'kr',                   // 언어팩 적용 부분 수정해야함 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    scrollModel: {
        //autoFit: false,
        type: 'Cell',
        mode: 'Block',
    },
    numberCell: {
        show: true
    },
    // hoverMode: 'row',
    wrap: false,
    hwrap: true,
    resizable: true,
    rowBorders: false,
    menuIcon: true,
    menuUI: {
        singleFilter: true,
        tabs: ['filter'] //'hideCols','filter'
    },
    contextMenu: {
        on: true,
        //header context menu items.
        headItems: function headItems(evt, ui) {
            return [
                {
                    //hide the selected columns
                    name: 'LABEL.COLUMN_HIDE',
                    action: function (evt, ui) {
                        // ui.column.hidden = true;
                        // this.refreshCM(this.colModel);
                        if (ui.column.dataIndx) {
                            this.Columns().hide({
                                diHide: [ui.column.dataIndx]
                            })
                        } else if (ui.column.colModel) {
                            for (var i = 0; i < ui.column.colModel.length; i++) {
                                this.Columns().hide({
                                    diHide: [ui.column.colModel[i].dataIndx]
                                })
                            }
                        }
                        // } else {
                        //     ui.column.hidden = true;
                        //     this.refreshHeader();
                        // }
                    }
                },
                {
                    //show columns
                    name: 'LABEL.COLUMN_HIDE_ALL_CANCEL',
                    action: function (evt, ui) {
                        var hiddenCols = this.colModel.filter(element => element.hidden == true);
                        var di = [];
                        for (var i = 0; i < hiddenCols.length; i++) {
                            di.push(hiddenCols[i].dataIndx);
                        }
                        this.Columns().hide({
                            diShow: di
                        })
                    }
                },
                {
                    //show columns
                    name: 'LABEL.SAVE_COLUMN',
                    action: saveColumn
                },
                {
                    //show columns
                    name: 'LABEL.RESET_COLUMN',
                    action: resetColumn
                },
            ]
        },
    },
    // filterModel: { header: true, type: 'local', menuIcon: true },
    trackModel: { on: true }, //to turn on the track changes.            
    //scrollModel: { autoFit: true },
    swipeModel: { on: false },
    editModel: {
        //saveKey: $.ui.keyCode.ENTER, //////// !!!!!!!!!!!!!!!!!!!!!!!!
        keyUpDown: true
    },
    editor: { select: true },
    //title: "<b>Batch Editing & Filtering & Sorting Combined</b>",
    showTitle: false,
    sortModel: { ignoreCase: true },
    history: function (evt, ui) {
        var $grid = this.widget();

        // if (ui.canUndo != null) {
        //     $("button.changes", $grid).button("option", { disabled: !ui.canUndo });
        // }
        // if (ui.canRedo != null) {
        //     $("button:contains('Redo')", $grid).button("option", "disabled", !ui.canRedo);
        // }
        // $("button:contains('Undo')", $grid).button("option", { label: 'Undo (' + ui.num_undo + ')' });
        // $("button:contains('Redo')", $grid).button("option", { label: 'Redo (' + ui.num_redo + ')' });
    },
    change: onCellvaluechanged,
    beforePaste: onBeforePaste,
    cellClick: function (event, ui) {
        if (ui.dataIndx === "ROWRADIO") {
            if (rowSelectedIndexByRadio === null || rowSelectedIndexByRadio !== ui.rowIndx) {
                rowSelectedIndexByRadio = ui.rowIndx
                rowSelectedDataByRadio = ui.rowData

                grid.value.refreshCell({ rowIndx: ui.rowIndx, dataIndx: 'ROWRADIO' })
                ui.$td[0].querySelector('input').setAttribute('checked', true)

                onGrdRowClickRadio(ui.rowData)
            }
            //ui.$td[0].querySelector('input').checked = true
        }
        rowSelectedIndex = ui.rowIndx
        rowSelectedData = ui.rowData
        cellSelectedColumnIndex = ui.colIndx
    },
    cellDblClick: function (event, ui) {
        onGrdCellDblClick(event, ui)
    },
    dataReady: function (event, ui) {
        onGrdDataReady(event, ui)
    },
    check: function (event, ui) {
        
    },
    // 화면 갱신 후 이벤트
    refresh: function (event, ui) {
        hideloadelement();
    },
    columnOrder(event, ui) {
        // DRAG AND "DROP"
        // if(this.options.freezeCols > ui.colIndx) {
        //     event.preventDefault();
        //     event.stopPropagation();
        //     return false;
        // }
    },
    // editorBegin: onCellBeginEdit,
    postRenderInterval: -1,

}

onMounted(() => {
    cClose = props.cClose       // 닫기 버튼

    let baseGrid = {
        refCode: refCode,
        baseComponentData: baseComponentData,
        baseBindingData: baseBindingData,
        menuCode: menuCode,
        gridType: gridType,
        options: options,
        grdColumns: grdColumns,
        treeModel: treeModel,
        onEditable: onEditable,
        onGrdCellButtonClick: onGrdCellButtonClick,
        onColumnCls: onColumnCls,
        onEditable: onEditable,
        setCellData: setCellData ,
        getRowSelectedIndexByRadio: getRowSelectedIndexByRadio,
        getRowSelectedDataByRadio: getRowSelectedDataByRadio,
        getRowSelectedIndex: getRowSelectedIndex,
        getRowSelectedData: getRowSelectedData,
        getCellSelectedColumnIndex: getCellSelectedColumnIndex,

    }
    //let currentCon = getCurrentInstance()
    if(baseComponentData != null) {
        // 값이 없을 경우는 GridbaseInit을 이용하여 처리
        GridInit(baseGrid)
    }
})

/**
 * 초기 값을 수동으로 주입
 * @param {*} data 그리드 초기값들
 */
function GridbaseInit(lBaseComponentData, lGridType) {
    baseComponentData = lBaseComponentData
    gridType = lGridType
    GridInit()
}

/** 그리드 초기화 */
function GridInit(baseGrid) {

    // colModel을 ottions.colModel에 자동으로 붙여줌
    options['colModel'] = createColModel(t, baseGrid)

    // 그리드 생성
    grid.value = pq.grid(grd.value, options)
    $(grid.value.element).pqGrid("refreshDataAndView")

    grdInitFinish()
}

/** 업데이트 시작 */
function beginupdate() {

}

/** 업데이트 종료 */
function endupdate() {

}

function updatebounddata() {
}

function getrows() {
    return grid.value.getData()
}

/** 로딩 시작 처리 */
function showloadelement() {
    if (grid != null && grid.value.hasOwnProperty('element')) {
        $(grid.value.element).pqGrid("showLoading")
    }

}

/** 로딩 종료 */
function hideloadelement() {
    if (grid != null && grid.value.hasOwnProperty('element')) {
        $(grid.value.element).pqGrid("hideLoading")
    }

}

/** 그리드 초기화 종료 */
function grdInitFinish() {
    emit('InitFinish', this)
}

/** 그리드 데이터 초기화 */
function grdDataClear() {
    try {
        grid.value.clear()
        grdEditList.splice(0, grdEditList.length)
        grdCheckList.splice(0, grdCheckList.length)
        grdSearchParams = Obejct.assign({}, grdOrgSearchParams)

    } catch(err) {
        console.log('grdDataClear')
        console.dir(err)
    }
}

/**
 * 조회 기능(그리드 마스터 / ref 사용)
 * @param {*} paramsRef 조회 인자(ref 사용)
 * @param {*} fixDatasRef 고정 인자(ref 사용)
 * @param {string} options { hideMessageYN: alert 사용 여부 }
 */
async function grdSearchRef(paramsRef, fixDatasRef, options) {
    if(options == null) options = {}
    let params = {}
    if (paramsRef != null) {
        var tmpkeys = Object.keys(paramsRef)
        for (let i = 0; i < tmpkeys.length; i++) {
            params[tmpkeys[i]] = paramsRef[tmpkeys[i]].value
        }
    }
    let fixDatas = {}
    if (fixDatasRef != null) {
        var tmpkeys = Object.keys(fixDatasRef)
        for (let i = 0; i < tmpkeys.length; i++) {
            fixDatas[tmpkeys[i]] = paramsRef[tmpkeys[i]].value
        }
    }

    return await grdSearch(params, fixDatas, options)
}

/**
 * 조회
 * @param {JSON} params 조회 조건(없는 경우 기존 조건을 이용)
 * @param {string} fixDatas 고정 조회 조건(없는 경우 기존 조건 사용)
 * @param {string} options { hideMessageYN: alert 사용 여부 }
*/
 async function grdSearch(params, fixDatas, options) {
    if(options == null) options = {}
    // 조회 조건을 임시 기록
    if (params != null) {
        grdSearchParams = Object.assign(grdSearchParams, params)
    }

    // 조회 시 고정으로 포함 시켜야 하는 값 추가
    if (fixDatas != null) {
        grdSearchParams = Object.assign(grdSearchParams, fixDatas)
    }

    var tmp_data = grid.value.options.dataModel

    if ((grdEditList.length > 0 || grdCheckList.length) && options['hideMessageYN'] != 'Y' ) {
        // 수정 또는 삭제 할 내역이 있습니다.\n 작업 데이터를 버리고 조회 하시겠습니까?"
        const tmpAlert = await vfmBasePopup(Comfirm, { state: 'info', contents: t('CONFIRM.CONFIRM0003') })
        if (tmpAlert == false) return
    }
    showloadelement();

    grdEditList.splice(0, grdEditList.length)
    grdCheckList.splice(0, grdCheckList.length)
    grdSaveData.splice(0, grdSaveData.length)
    grid.value.commit()

    // 수정 사항 초기화
    try {
        tmp_data.data.splice(0, tmp_data.data.length);
    } catch (err) {
        console.log('grdSearch')
        console.dir(err)
    }
    var pref = '';
    if (baseComponentData.SYSTEM_TYPE == 'SYS') {
        pref = 'Sys';
    }

    store.dispatch('NGCore/get' + pref + 'Data', { METHOD_CODE: baseComponentData.COMPONENT_CODE, pref: baseComponentData.SYSTEM_TYPE, paramsdata: grdSearchParams }).then((resdata) => {
        let rtndata = resdata.rtndata;
        switch (gridType) {
            case "ALLUNPIVOTGRID":      // 전체를 UNPIVOT처리

                for (var i = 0; i < rtndata.length; i++) {
                    //grdDataOrg <== 받은 데이터를 pivot 처리하여 넣어줘야 함
                }

                grdLocalDataBinding(grdDataOrg)
                break

            default:
                grdLocalDataBinding(rtndata)
                break
        }

    }).catch((error) => {
        // error.response.data를 이유하여 적저리 처리해야함
        // 처리 중 오류 발생
        console.log('grdSearch2')
        console.dir(error)
        
        vfmBasePopup(Alert, { state: 'error', contents: t('ERRORMSG.ERRORMSG0009') })
        $(grid.value.element).pqGrid("refreshDataAndView")

        let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
        store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })

        hideloadelement()
    })

}


/**
 * 데이터를 직접 그리드에 바인딩 처리
 */
function grdLocalDataBinding(data) {
    var tmp_data = grid.value.options.dataModel
    // 수정 사항 초기화
    try {
        tmp_data.data.splice(0, tmp_data.data.length);
    } catch (err) {
        console.log('grdLocalDataBinding')
        console.dir(err)
    }


    // 초기화
    //self.$refs.grd.clear()
    beginupdate()
    grdDataOrg = data;

    updatebounddata()

    // 데이터 바인딩
    var tmp_data = grid.value.options.dataModel
    if (grdDataOrg.hasOwnProperty('data')) {
        grdDataOrg.data.map((row) => {
            tmp_data.data.push(row)
        })
    } else {
        grdDataOrg.map((row) => {
            tmp_data.data.push(row)
        })
    }
    //$ㅋㄹtmp_data.data.push(...grdDataOrg) 
    // 키컬럼 설정(추가, 수정, 삭제 이력을 위한 필수사항)

    tmp_data.recIndx = 'pq_ri'
    grid.value.options.dataModel = tmp_data
    // $(grid.value.element).pqGrid('option', 'dataModel', tmp_data)
    // //$(grid.value.element).pqGrid('dataModel', grdSource.localdata)
    // $(grid.value.element).pqGrid("refreshDataAndView")

    rowSelectedIndexByRadio = null
    rowSelectedDataByRadio = null
    rowSelectedIndex = null
    rowSelectedData = null
    cellSelectedColumnIndex = null
    grid.value.setSelection(null)
    grdEditList.splice(0, grdEditList.length);
    grdCheckList.splice(0, grdCheckList.length);
    //grid.value.rollback()        // 수정 내역 최소처리

    // 자동 선택 오류가 있어서 빼버림
    // // 내용이 있는 경우 첫 row 자동 선택
    // if (getrows().length > 0) {
    //     try {
    //         grid.value.setSelection({ rowIndx: getrows()[0].pq_ri, colIndx: 0 })
    //     } catch(err) {
    //         console.log('grdLocalDataBinding')
    //         console.dir(err)
    //     }
    // }


    grid.value.refreshDataAndView()
    endupdate()
    hideloadelement();


}

// 
/**
 * grd row 추가
 * @param {JSON} data               등록할 ROW 정보
 * @param {String} insertType       insert처리할 위치(auto: 포커스 없으면 맨끝, focus: 포커스 아래, first: 첫행, last: 맨끝)
 * @returns 
 */
 function grdAddRow(data = {}, insertType = 'auto') {
    var dataCount = grid.value.getData().length
    var selectData = grid.value.Selection().getSelection()
    if (insertType === 'focus' && dataCount > 0 && selectData.length == 0) {
        // 행을 먼저 선택 하세요.
        vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0009') })
        return
    }

    try {
        var tmpindex = 0
        switch (insertType) {
            case 'focus':
                if (dataCount > 0 && selectData != null) {
                    tmpindex = selectData[selectData.length - 1].rowIndx + 1
                } else {
                    tmpindex = 0
                }
                break
            case 'first':
                tmpindex = 0
                break
            case 'last':
                tmpindex = dataCount
                break
            case 'auto':
                if((rowSelectedIndex || '') == '') {
                    tmpindex = dataCount
                } else {
                    tmpindex = selectData[selectData.length - 1].rowIndx + 1
                }
                break

            default:
                tmpindex = 0
                break
        }

        var tmp_data = { ROWRADIO: 'N', METHOD: 'A', ROWCHECK: 'N' }
        var tmp_keys = Object.keys(data)
        for (var rowidx = 0; rowidx < tmp_keys.length; rowidx++) {
            if (data[tmp_keys[rowidx]] != null && data[tmp_keys[rowidx]] != '') {
                tmp_data[tmp_keys[rowidx]] = data[tmp_keys[rowidx]]
            } else {
                tmp_data[tmp_keys[rowidx]] = ''
            }
        }
        tmp_data['pq_ri'] = dataCount

        // for(let col = 0; col < this.grdDataAdapter._source.datafields.length; col++) {
        //     // type도 신경 써야 할것으로 보임
        //     if(!tmp_data.hasOwnProperty(this.grdDataAdapter._source.datafields[col].name)) {
        //         tmp_data[this.grdDataAdapter._source.datafields[col].name] = null
        //     }
        // }

        //this.$refs.grd.addrow(null, tmp_data, tmpindex);
        // 행 추가와 같이 데이터 넣는건 현재 버그 인거 같음
        grid.value.addRow({ rowData: Object.assign({}, tmp_data), rowIndx: tmpindex })
        grid.value.setSelection({ rowIndx: tmpindex, colIndx: 0 })
        // if (tmpindex !== 0) {
        //     //grid.value.addRow({ newRow: Object.assign({}, tmp_data), rowIndx: tmpindex })
        //     grid.value.addRow({ newRow: Object.assign({}, tmp_data), rowIndx: tmpindex })
        //     grid.value.setSelection({ rowIndx: tmpindex, colIndx: 0 })
        // } else {
        //     grid.value.addRow({ newRow: {} })
        // }
        //grid.value.options.dataModel.data[tmpindex] = Object.assign(grid.value.options.dataModel.data[tmpindex], tmp_data)
        let tmp = Object.assign(grid.value.options.dataModel.data[tmpindex], tmp_data)
        
        var tmp_keys = Object.keys(tmp)
        for(let ii = 0; ii < tmp_keys.length; ii++) {
            grid.value.options.dataModel.data[tmpindex][tmp_keys[ii]] = (tmp_data[tmp_keys[ii]] || null)
        }
        //grid.value.refreshRow( {rowIndx:tmpindex} )

        // 수정 이력으로 추가
        if (grdEditList.indexOf(grid.value.getData()[tmpindex]) === -1) {
            grdEditList.push(grid.value.getData()[tmpindex])
        }

        // 선택이 없으면 추가된 row를 자동 선택
        if (grid.value.Selection().getSelection().length === 0) {
            grid.value.setSelection({ rowIndx: tmpindex, colIndx: 0 })
        }

        
        //grid.value.refreshCell({ rowIndx: tmpindex, dataIndx: 'METHOD' })

        // if(insertType === 'last') {
        //     grid.value.setSelection( { rowIndx: tmpindex, colIndx: tmpindex })
        // }

    } catch (err) {
        console.log('grdAddRow')
        console.dir(err)
    }

}

/**
 * 선택이 있으면 하위 선택이 없으면 root에 컬럼 추가
 */
function grdAddRowChild(data = {}) {

    var dataCount = grid.value.getData().length
    //var selectData = grid.value.Selection().getSelection()
    var tmpDataList = grid.value.getData()

    try {
        // 기초 데이터 준비
        var tmp_data = { ROWRADIO: 'N', METHOD: 'A', ROWCHECK: 'N' }
        var tmp_keys = Object.keys(grdColumns)
        for (var rowidx = 0; rowidx < tmp_keys.length; rowidx++) {
            if (data[tmp_keys[rowidx]] != null && data[tmp_keys[rowidx]] != '') {
                tmp_data[tmp_keys[rowidx]] = data[tmp_keys[rowidx]]
            } else {
                tmp_data[tmp_keys[rowidx]] = ''
            }

            // if (rowSelectedIndex != null) {
            //     // TREE의 경우 선택한 로우를 상위로 설정
            //     tmp_data[this.treeModel.parentId] = tmpDataList[rowSelectedIndex].SEQ
            //     tmp_data['id'] = tmp_data[this.treeModel.id]
            //     tmp_data['parentId'] = tmp_data[this.treeModel.parentId]
            // }
        }

        var tmpindex = 0
        // if(this.componentType === 'TREEGRID' && rowSelectedIndex != null) {
        //     // radio selection이 있는 경우
        //     var tmpTree = grid.value.Tree()
        //     tmpTree.addNodes([tmp_data])

        // } else {

        // 행 추가와 같이 데이터 넣는건 현재 버그 인거 같음
        if (rowSelectedIndex != null) {
            // 셀렉트가 있는 경우
            var tmpindex = grid.value.Selection().getSelection()[grid.value.Selection().getSelection().length - 1].rowIndx + 1
            //grid.value.addRow({ newRow: tmp_data, rowIndx: tmpindex })
            grid.value.Tree().addNodes([tmp_data], tmpindex)
        } else {
            // 선택이 없는 경우
            grid.value.addRow({ newRow: {}, rowIndx: dataCount })
            tmpindex = dataCount
        }

        let tmp = Object.assign(grid.value.options.dataModel.data[tmpindex], tmp_data)
        
        var tmp_keys = Object.keys(tmp)
        for(let ii = 0; ii < tmp_keys.length; ii++) {
            grid.value.options.dataModel.data[tmpindex][tmp_keys[ii]] = (tmp_data[tmp_keys[ii]] || null)
        }

        grid.value.refreshRow( {rowIndx:tmpindex} )
        // }

        // 수정 이력으로 추가
        if (grdEditList.indexOf(tmp_data) === -1) {
            grdEditList.push(grid.value.getData()[tmpindex])
        }

        // 선택이 없으면 추가된 row를 자동 선택
        if (grid.value.Selection().getSelection().length === 0) {
            grid.value.setSelection({ rowIndx: tmpindex, colIndx: 0 })
        }
        grid.value.refreshCell({ rowIndx: tmpindex, dataIndx: 'METHOD' })

    } catch (err) {
        console.log('grdAddRowChild')
        console.dir(err)
    }

}

/**
 * 그리드 행 삭제(DB에 반영하지 않음)
 * { deleteRowYN : 행 삭제(숨김) 처리, hideMessageYN: alert 사용 여부 }
 */
async function grdDeleteRow(options) {
    if(options == null) options = {}
    var dataCount = grid.value.getData().length
    
    var deleteList = []
    if(grdCheckList.length == 0) {
        vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0010') })
        return
    }

    // 정말로 삭제 대상으로 하겠습니까?(N을 넣으면 메시지 표시)
    if(options['hideMessageYN'] == 'N') {
        let rtn = await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.CHK_DELETE') })
        if(rtn == false) return
    }
    
    grdCheckList.map(function (row) {
        //if (row.pq_ri !== null) {
            if(row['METHOD'].indexOf('A') === -1) {
                if(row['METHOD'].indexOf('D') === -1) {
                    row.METHOD = row.METHOD + 'D';
                    row.ROWCHECK = 'N';
                    if(options['deleteRowYN'] == 'Y') {
                        grid.value.deleteRow({ rowIndx: row.pq_ri })
                    } else {
                        var datamodeltemp = grid.value.options.dataModel.data;
                        var datamodeltemppqindex = datamodeltemp.findIndex((element) => element.pq_ri == row.pq_ri);
                        grid.value.refreshCell({ rowIndx: datamodeltemppqindex, dataIndx: 'METHOD' })
                        grid.value.refreshCell({ rowIndx: datamodeltemppqindex, dataIndx: 'ROWCHECK' })
                    }
                    grdEditList.push(row);
                }
            } else {
                grdEditList.splice(grdEditList.indexOf(row), 1)
                grid.value.deleteRow({ rowIndx: row.pq_ri })
            }

        //}
    })
    // 삭제 처리 후 체크리스트 클리어
    grdCheckList.splice(0, grdCheckList.length)

}

async function grdDeleteWithDB(reloadYn = 'Y', serviceUri = '') {
    if(grdCheckList.length == 0) {
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0010') })
        return
    }

    // DB에서 데이터 삭제후 조회
    // 정말로 삭제하시겠습니까?
    const tmpAlert = await vfmBasePopup(Comfirm, { state: 'info', contents: t('CONFIRM.CONFIRM0002') })
    if (tmpAlert == false) return

    showloadelement()

    // 처리 대상 추출
    let params = []
    
    beginupdate()
    for(let rowIdx = 0; rowIdx < grdCheckList.length; rowIdx++) {
        var row =  grdCheckList[rowIdx]

        if(row['METHOD'].indexOf('A') === -1) {
            var tmp_data = Object.assign({}, row)
            tmp_data.METHOD = 'D'
            params.push(tmp_data);
        } else {
            grid.value.deleteRow({ rowIndx: row.pq_ri })
        }

        var pqindex = grdEditList.findIndex((element) => element.pq_ri == row.pq_ri);
        grdEditList.splice(pqindex, 1);
    }
    endupdate()
    
    if(params.length == 0) {
        self.hideloadelement();
        return
    }

    try {
        let pref = '';
        if (baseComponentData.SYSTEM_TYPE == 'SYS') {
            pref = 'Sys';
        }
        let rtndata = await store.dispatch('NGCore/save' + pref + 'Data', { METHOD_CODE : baseComponentData.COMPONENT_CODE, paramsdata : params, serviceuri : serviceUri })
        if(options['hideMessageYN'] != 'Y') {
            await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0003') })
        }

        if(reloadYn) {
            // 변경 내역 초기화
            grid.value.commit()
            // self.grdEditList.splice(0, self.grdEditList.length);
            grdCheckList.splice(0, grdCheckList.length);
            grdSearch(grdSearchParams)
        } else {
            // 재 조회를 하지 않으면 수기 초기화 로직 필요
            beginupdate()
            grid.value.commit({ type: 'delete'})
            grdCheckList.splice(0, grdCheckList.length);
            se.endupdate()
        }

        return true

    } catch(err) {
        // error.response.data를 이유하여 적저리 처리해야함
        // 처리 중 오류 발생
        console.log('grdDeleteWithDB')
        console.dir(err)
        
        vfmBasePopup(Alert, { state: 'error', contents: t('ERRORMSG.ERRORMSG0009') })
        $(grid.value.element).pqGrid("refreshDataAndView")

        let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
        store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })

        hideloadelement()

        return fals

    }
}

/**
 * DB에 반영(현재 사용 안함)
 */
function grdRemoveRow(reload_yn = true, serviceUri = '') {
    // *** 추가 한 항목만 삭제 할 경우 재조회는 하지 않음
    //     필요성도 없고 수정중이라면 재조회를 싫어 할수 있음(아니면 재조회를 물어보던가)

    // 삭제 대상이 있는지 체크
    if (grdCheckList.length === 0) {
        // 삭제 대상을 선택 후 사용하세요.
        //this.$NUAlert({ data: { state: 'warning', contents: this.$t('MSG0010'), buttons: ['ok'] } })

        return
    }

    /*
    // D메소드 변경
    // 정말로 삭제하시겠습니까?
    this.$NUAlert({ data : { state: 'info', contents: this.$t('CONFIRM0002') } }).then(result => {
        if(result) {
            showloadelement();
            // 처리 대상 추출
            var params = []
            beginupdate()
            
            var datamodeltemp = grid.value.options.dataModel.data;
            for(let rowIdx = 0; rowIdx < grdCheckList.length; rowIdx++) {
                var row =  grdCheckList[rowIdx]

                if(row['METHOD'].indexOf('A') === -1) {
                    row.METHOD = 'D';
                    row.ROWCHECK = 'N';
                    var datamodeltemppqindex = datamodeltemp.findIndex((element) => element.pq_ri == row.pq_ri);
                    grid.value.refreshCell({ rowIndx: datamodeltemppqindex, dataIndx: 'METHOD' })
                    grid.value.refreshCell({ rowIndx: datamodeltemppqindex, dataIndx: 'ROWCHECK' })
                    grdEditList.push(grdCheckList[rowIdx]);
                } else {
                    grid.value.deleteRow({ rowIndx: row.pq_ri })
                }

                // var pqindex = grdEditList.findIndex((element) => element.pq_ri == row.pq_ri);
                // grdEditList.splice(pqindex, 1);
            }
            endupdate()
            
            grdCheckList.splice(0, grdCheckList.length);
            grid.value.commit();
            hideloadelement();
        }
    })
    */

    // DB에서 데이터 삭제후 조회
    // 정말로 삭제하시겠습니까?
    this.$NUAlert({ data: { state: 'info', contents: this.$t('CONFIRM0002') } }).then(result => {
        if (result) {
            showloadelement();
            // 처리 대상 추출
            var params = []
            beginupdate()

            var datamodeltemp = grid.value.options.dataModel.data;
            for (let rowIdx = 0; rowIdx < grdCheckList.length; rowIdx++) {
                var row = grdCheckList[rowIdx]

                if (row['METHOD'].indexOf('A') === -1) {
                    var tmp_data = Object.assign({}, row)
                    tmp_data.METHOD = 'D'
                    params.push(tmp_data);
                } else {
                    grid.value.deleteRow({ rowIndx: row.pq_ri })
                }

                var pqindex = grdEditList.findIndex((element) => element.pq_ri == row.pq_ri);
                grdEditList.splice(pqindex, 1);
            }
            endupdate()

            if (params.length == 0) {
                hideloadelement();
                return
            }
            this.$store.dispatch('nuisys/comonsyssave', { methodcode: baseComponentData.COMPONENT_CODE, paramsdata: params, serviceuri: serviceUri }).then(
                (rtndata) => {

                    if (reload_yn) {
                        // 변경 내역 초기화
                        grid.value.commit()
                        // grdEditList.splice(0, grdEditList.length);
                        grdCheckList.splice(0, grdCheckList.length);
                        grdSearch(grdSearchParams)
                    } else {
                        // 재 조회를 하지 않으면 수기 초기화 로직 필요
                        beginupdate()
                        grid.value.commit({ type: 'delete' })
                        grdCheckList.splice(0, grdCheckList.length);
                        endupdate()
                    }
                    //this.$NUAlert({ data : { state: 'info', contents: this.$t(rtndata.msg) } })
                    //this.$NUAlert({ data: { state: 'info', contents: this.$t('MSG0022'), buttons: ['ok'] } })
                    hideloadelement();
                },
                error => {
                    // error.response.data를 이유하여 적저리 처리해야함
                    // 처리 중 오류 발생
                    //this.$NUAlert({ data: { state: 'error', contents: this.$t('ERRORMSG0009'), buttons: ['ok'] } })
                    console.log('grdRemoveRow')
                    console.dir(error)

                    hideloadelement();
                    let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
                    store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })
                }
            );
        }
    })
}

/**
 * 그리드 저장
 * @param {string} hideComfirmYn 확인창 사용 여부(N: 사용)
 * @param {string} reloadYn 저장 후 재조회여부(Y: 재조회)
 * @param {JSON} options 옵션처리 기능 : { hideComfirm(N) : comfirm 숨김, hideMessageYN(N) : alert 숨김, reloadYn(Y) : 재조회 }
 * @param {Array} batch 배치 처리를 위한 정보 : [ 'BATCH' ]
 */
async function grdSave(options, batch) {
    if(options == null) options = {}
    if (grdEditList.length === 0) {
        //저장할 내용이 없습니다.
        vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0006') })
        return
    }
    
    for (let rowIdx = 0; rowIdx < grdEditList.length; rowIdx++) {
        let row = grdEditList[rowIdx]
        if(!!options && !!options.boardName) {
            row['TNAME'] = options.boardName;
        }
        // key id에 값이 있는지 체크
        let tmp_keys = Object.keys(grdColumns)

        for (let col = 0; col < tmp_keys.length; col++) {
            let tmp_column = grdColumns[tmp_keys[col]]

            if (tmp_column.KEYID_YN === 'Y' && row[tmp_keys[col]] == null | row[tmp_keys[col]] === '') {
                // key 값은 필수로 입력 하셔야 합니다[{0}]
                await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0007', [t(tmp_keys[col])]) })
                return
            }

            if (tmp_column.NULLABLE_YN === 'N' && row[tmp_keys[col]] == null | row[tmp_keys[col]] === '') {
                // 빈 값을 허용하지 않는 컬럼에 데이터가 없습니다.[{0}]
                await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0008', [t(tmp_keys[col])]) })
                return
            }
            
            // 숫자 처리 수정해야함
        }
    }

    if(options['hideComfirm'] != 'Y') {
        // 저장하시겠습니까? 확인장
        let tmpAlert = await vfmBasePopup(Comfirm, { state: 'info', contents: t('CONFIRM.CONFIRM0001') })
        if (tmpAlert == false) return
    }

    // 저자 데이터 추출전 콜백 처리
    executeGridEventManager('beforeSaveNoData', {})

    // 처리 대상 추출
    var params = []
    for (let rowIdx = 0; rowIdx < grdEditList.length; rowIdx++) {
        var row = grdEditList[rowIdx]

        // key id에 값이 있는지 체크
        var tmp_keys = Object.keys(grdColumns)

        var tmp_data = Object.assign({}, row)
        if (tmp_data.hasOwnProperty('parent')) {
            delete tmp_data.parent
        }
        if (tmp_data.hasOwnProperty('records')) {
            delete tmp_data.records
        }
        if (tmp_data.hasOwnProperty('children')) {
            delete tmp_data.children
        }

        if (tmp_data['METHOD'].indexOf('A') !== -1) {
            tmp_data['METHOD'] = 'A'
        }
        if (tmp_data['METHOD'].indexOf('D') !== -1) {
            tmp_data['METHOD'] = 'D'
        }
        
        // 제거된 컬럼이 있는 경우 복원(PQGRID에서 다른 해결 방법이 필요 / DEL로 CELL 값 제거시 현상)
        var tmp_keys = Object.keys(grdColumns)
        for (var rowidx = 0; rowidx < tmp_keys.length; rowidx++) {
            if (!tmp_data.hasOwnProperty(tmp_keys[rowidx]) || tmp_data[tmp_keys[rowidx]] === undefined) {
                tmp_data[tmp_keys[rowidx]] = null
            }
        }

        // var tmp_keys = Object.keys(tmp_data)
        // for(var rowIdx2 = 0; rowIdx2 < tmp_keys.length; rowIdx2++) {
        //     if(typeof tmp_data[tmp_keys[rowIdx2]] === 'boolean') {
        //         tmp_data[tmp_keys[rowIdx2]] = tmp_data[tmp_keys[rowIdx2]] === true ? 'Y' : 'N';
        //     }
        // }


        params.push(tmp_data);
    }

    // 저장 데이터 전송 직전에 콜백 호출
    grdSaveData = params
    executeGridEventManager('beforeSaveNoData', grdSaveData)

    var pref = '';
    if (baseComponentData.SYSTEM_TYPE == 'SYS') {
        pref = 'Sys';
    }
    
    var param = {
        METHOD_CODE: baseComponentData.COMPONENT_CODE,
        pref: baseComponentData.SYSTEM_TYPE,
        SQLLOG_YN: 'Y',             // db log 남길지 유무
        paramsdata: grdSaveData
    }
    if(batch != null) {
        param['BATCH'] = batch
    }


    showloadelement();
    // 저장
    try {
        let rtndata = await store.dispatch('NGCore/save' + pref + 'Data', param)
        if(rtndata.status) {
            if(options['hideMessageYN'] != 'Y') {
                await vfmBasePopup(Alert, { state: 'info', contents: t(rtndata.rtnmsgcode) })
            }
        } else {
            await vfmBasePopup(Alert, { state: 'error', contents: t(rtndata.errorcode) })
            hideloadelement()
            return
        }

        // 저장 후 이벤트 처리
        executeGridEventManager('afterSave', {})

        beginupdate()
        // 작업 내역 모두 초기화
        grdEditList.splice(0, grdEditList.length)
        grdCheckList.splice(0, grdCheckList.length)
        grdDataOrg.splice(0, grdDataOrg.length)
        grdSaveData.splice(0, grdSaveData.length)
        grid.value.commit()
        endupdate()

        if (options['reloadYn'] != 'N') {
            // 변경 내역 초기화
            grdSearch(null, null, options)
        } else {
            // 재 조회를 하지 않으면 수기 초기화 로직 필요
            hideloadelement()
        }        
    } catch(error) {
        // 처리 중 오류 발생
        vfmBasePopup(Alert, { state: 'info', contents: t('ERRORMSG.ERRORMSG0009') })

        hideloadelement()
        let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
        store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })
    }
    
    
}

/** Row 클릭 */
function onGrdRowClick(event) {
    
}

/** Row 클릭(Radio) */
function onGrdRowClickRadio(row) {
    emit('RowClickRadio', this, row)
}

/** Row Double Click */
function onGrdCellDblClick(event, ui) {
    emit('CellDblClick', this, event, ui, cClose)
}

/** Data Ready */
function onGrdDataReady(event, ui) {
    emit('DataReady', this, event, ui)
}

// grd cell click
function onGrdCellClick(event, ui) {
    //this.$refs.grd._source().keyid
    if (event.args.row.bounddata != null) {
        emit('CellClick', this, event, ui)
        //this.grd02Search(event.args.row.bounddata);
    }
}

/*
onCellBeginEdit(event, ui) {
    if(ui.column.orgColumn.COLUMN_COMP_TYPE == "DROPDOWNLIST") {
        // Datalist 값이 있는 경우 받아서 넘김
        if(baseComponentData.DATALIST[ui.column.orgColumn.LISTDATA_BINDING] != null) {
            ui.column['editor'].options = 
            baseComponentData.DATALIST[ui.column.orgColumn.LISTDATA_BINDING].filter((r) => {
                return r.CODE != ''
            })
        }
    }
},
*/

function onHandlekeyboardnavigation(event) {
}

// grd cell 수정 시작 시 이벤트
function onGrdCellBeginEdit(row, datafield, columntype, value) {
    return this.ChkCellEdit(row, datafield, value)

    // // let args = event.args;
    // // let columnDataField = args.datafield;
    // // let rowIndex = args.rowindex;
    // // let cellValue = args.value;
    // // return false

    // // 수정 조건이 추가로 필요함(해당 컬럼 수정 유무 체크)
    // this.grdCellOldValue = this.$refs.grd.getcellvalue(row, datafield)
    // if(grdColumns[datafield].EDIT_YN === 'N') {
    //     return false
    // }

    // // event.args.value = event.args.oldvalue
    // // event.args.oldvalue
    // if(grdColumns[datafield].EDIT_YN === 'N'
    //   || grdColumns[datafield].KEYID_YN === 'Y') {
    //     return false
    // }


    // // 수정 조건
    // if(this.grdDataAdapter._source.keyid.indexOf(datafield) === -1
    //     || (this.grdDataAdapter._source.keyid.indexOf(datafield) !== -1)
    //         && this.$refs.grd.getrowdata(row).METHOD === 'A') {
    //     // 키 컬럼 X / 키컬럼이여도 row add 상태인 경우는 수정
    //     return true;
    // }

    // //this.grdDataAdapter._source.datafields.filter((row) => { return row.name === datafield && row.col_comp_type === "NUMBER" })
    // return false
}


/** cell에 데이터 바인딩 */
function setCellData(rowIndex, dataIndx, cellValue) {
    var tmp_data = grid.value.options.dataModel.data
    tmp_data[rowIndex][dataIndx] = cellValue
    if (tmp_data[rowIndex].hasOwnProperty('METHOD') === false) {
        tmp_data[rowIndex]['METHOD'] = 'U'
    } else {
        if (tmp_data[rowIndex]['METHOD'].indexOf('U') === -1) {
            tmp_data[rowIndex]['METHOD'] = tmp_data[rowIndex]['METHOD'] + 'U'
        }
    }
    if (grdEditList.indexOf(grid.value.getData()[rowIndex]) === -1) {
        grdEditList.push(grid.value.getData()[rowIndex])
    }

    grid.value.refreshCell({ rowIndx: rowIndex, dataIndx: dataIndx })
    grid.value.refreshCell({ rowIndx: rowIndex, dataIndx: 'METHOD' })
}

/** cell에 데이터 바인딩 */
function setRowData(rowIndex, value) {
    var tmp_data = grid.value.options.dataModel.data
    for (var c in tmp_data[rowIndex]) {
        tmp_data[rowIndex][c] = value[c];
        
        grid.value.refreshCell({ rowIndx: rowIndex, dataIndx: c })
    }
    // tmp_data[rowIndex][column] = cellValue
    if (tmp_data[rowIndex].hasOwnProperty('METHOD') === false) {
        tmp_data[rowIndex]['METHOD'] = 'U'
    } else {
        if (tmp_data[rowIndex]['METHOD'].indexOf('U') === -1) {
            tmp_data[rowIndex]['METHOD'] = tmp_data[rowIndex]['METHOD'] + 'U'
        }
    }
    if (grdEditList.indexOf(grid.value.getData()[rowIndex]) === -1) {
        grdEditList.push(grid.value.getData()[rowIndex])
    }

    
    grid.value.refreshCell({ rowIndx: rowIndex, dataIndx: 'METHOD' })
}

/** row의 데이터 가져옴 */
function getRowData(rowIndex) {

}

/** 매치되는 데이터 검색 */
function search(column, cellvalue) {

}

/** row 선택 */
function selection(rowIndex) {

}

function onGrdCellEndEdit(row, datafield, columntype, value) {
    
    // if(this.$refs.grd == null) return
    // value = this.$refs.grd.getcellvalue(row, datafield)
    // // 값이 같으면 무조건 패스
    // if(!this.chkEndEdit(row, datafield, columntype, value)) {
    //     return
    // }

    // var rowStatus = this.$refs.grd.getcellvalue(row, 'METHOD')      // row 상태

    // if(datafield === 'rowchk') {
    //     // rowchk 수정시
    //     if(value === true) {
    //         if(rowStatus !== 'A') {
    //             this.$refs.grd.setcellvalue(row, 'METHOD', 'C')
    //         }
    //         grdCheckList.push(this.$refs.grd.getrowdata(row))
    //     } else {
    //         if(rowStatus !== 'A') {
    //             this.$refs.grd.setcellvalue(row, 'METHOD', '')
    //         }
    //         grdCheckList.splice(grdCheckList.indexOf(this.$refs.grd.getrowdata(row)), 1)
    //     }

    // } else if(rowStatus !== 'A') {
    //     // 일반 컬럼 수정시
    //     this.$refs.grd.setcellvalue(row, 'METHOD', 'U')

    //     // 수정 이력으로 추가
    //     if(grdEditList.indexOf(this.$refs.grd.getrowdata(row)) === -1) {
    //         grdEditList.push(this.$refs.grd.getrowdata(row))
    //     }
    // }

}

/** 셀 값 변경 시 */
function onCellvaluechanged(event, ui) {
    var tmp_dataModel = grid.value.options.dataModel
    var tmp_data = tmp_dataModel.data
    ui.updateList.map(chgrow => {

        // 값이 같으면 무조건 패스
        // var tmp_keys = Object.keys(chgrow)
        // if(chgrow === event.args.oldvalue) {
        //     return
        // }
        // if(!this.chkEndEdit(rowindex, datafield, columntype, value)) {
        //     return
        // }
        if (chgrow.hasOwnProperty('rowIndx') === false) {
            
        }

        if (ui.updateList[0].newRow.hasOwnProperty('ROWRADIO') === true) {
            onGrdRowClickRadio(ui.updateList[0].rowData)
            return
        }


        if (ui.updateList[0].newRow.hasOwnProperty('ROWCHECK') === true) {
            chgrow.rowIndx = getRowSelectedIndex()
            // 삭제 체크 관련
            if (ui.updateList[0].newRow.ROWCHECK === 'Y') {
                if (tmp_data[chgrow.rowIndx].hasOwnProperty('METHOD') === false) {
                    tmp_data[chgrow.rowIndx]['METHOD'] = 'C'
                } else {
                    tmp_data[chgrow.rowIndx]['METHOD'] = tmp_data[chgrow.rowIndx]['METHOD'] + 'C'
                }
                // 수정 리스트에 등록
                grdCheckList.push(tmp_data[chgrow.rowIndx])

            } else {
                // 수정 리스트에서 제거
                grdCheckList.splice(grdCheckList.indexOf(tmp_data[chgrow.rowIndx]), 1)
                tmp_data[chgrow.rowIndx]['METHOD'] = tmp_data[chgrow.rowIndx]['METHOD'].replace('C', '')
            }
            $(grid.value.element).pqGrid('refreshCell', { rowIndx: chgrow.rowIndx, dataIndx: 'METHOD' })

            return
        }

        if (tmp_data[chgrow.rowIndx].hasOwnProperty('METHOD') === false) {
            chgrow.rowIndx = getRowSelectedIndex()

            tmp_data[chgrow.rowIndx]['METHOD'] = 'U'
            // 수정 이력으로 추가
            if (grdEditList.indexOf(tmp_data[chgrow.rowIndx]) === -1) {
                grdEditList.push(tmp_data[chgrow.rowIndx])
            }
        } else if (tmp_data[chgrow.rowIndx].hasOwnProperty('METHOD') === true && tmp_data[chgrow.rowIndx]['METHOD'].indexOf('U') === -1) {
            chgrow.rowIndx = getRowSelectedIndex()

            tmp_data[chgrow.rowIndx]['METHOD'] = tmp_data[chgrow.rowIndx]['METHOD'] + 'U'
            // 수정 이력으로 추가
            if (grdEditList.indexOf(tmp_data[chgrow.rowIndx]) === -1) {
                grdEditList.push(tmp_data[chgrow.rowIndx])
            }
        }

        $(grid.value.element).pqGrid('refreshCell', { rowIndx: chgrow.rowIndx, dataIndx: 'METHOD' })

    })

}

// cellsrenderer: function(row, column, value, defaultHtml) {
//     return defaultHtml
// },
function onCellcellclassname(row, columnfield, value) {
    if (this.ChkCellEdit(row, columnfield, value)) {
        return "yellow"
    }
    return ""
}

/**
 * 수정 가능 유무 판단
 */
function onEditable(ui, column) {
    try {
        if (ui.rowData == null) return true      // rowData가 없는경우는 row추가일 가능성이 가장 높음

        if (grdColumns[column].EDIT_YN === 'Y') {
            if (grdColumns[column].KEYID_YN === 'Y') {
                // key 컬럼의 경우에는 추가 상태인경우에만 수정 가능
                if (ui.rowData['METHOD'] !== undefined && ui.rowData['METHOD'].indexOf('A') !== -1) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }

        } else {
            return false
            //return ui.rowData == null || !ui.rowData.disabled;
        }
    } catch (err) {
        console.log('onEditable')
        console.dir(err)
        
        return false
    }

}

/**
 * 붙여넣기시 제약 조건 추가
 */
function onBeforePaste(event, ui) {
    // ROWRADIO, METHOD, ROWCHECK
    var CM = grid.value.getColModel(),
        rows = ui.rows,
        area = ui.areas[0],
        c1 = area.c1;

    // 추후에 mothod 붙여넣기에서 빼던지 기존 값을 가져와 업데이트 처리해야함
}

// 수정 가능 유무 체크
function ChkCellEdit(row, columnfield, value) {
    try {
        if ((grdColumns[columnfield].KEYID_YN === 'N' && grdColumns[columnfield].EDIT_YN === 'Y')
            || (grdColumns[columnfield].KEYID_YN === 'Y' && grid.getrowdatabyid(row).METHOD === 'A')) {
            return true
        }
    } catch (err) {
        console.log('ChkCellEdit')
        console.dir(err)
    }


    return false

}

// combobox 생성
function editMultipleChoiceCell(ui, arr) {
    console.log('ParamGrid: Editing MultipleChoice Cell');

    var $cell = ui.$cell;
    var data = ui.data;

    //this won't work with Pro API
    //var cellData = $.trim(data[ui.rowIndx][ui.colIndx]);
    var cellData = $.trim(ui.rowData[ui.column.dataIndx]);

    var str = "";
    for (var i = 0; i < arr.length; i++) {

        var label = arr[i].NAME;
        var value = arr[i].CODE;

        //we are assuming the cell will contain the 'label' string rather than the value
        //we need to make sure this is the case
        if (cellData == label)
            str += "<option value='" + value + "' selected='selected'>" + label + "</option>";
        else
            str += "<option value='" + value + "'>" + label + "</option>";
    }
    var $sel = $("<select style='width: 100%; height: 100%'>" + str + "</select>")
        .appendTo($cell);
}

function RenderMultipleChoiceCell(ui, arr) {

    var cellData = $.trim(ui.rowData[ui.column.dataIndx]);

    for (var i = 0; i < arr.length; i++) {

        var label = arr[i].NAME;
        var value = arr[i].CODE;

        //we are assuming the cell will continue the 'label' string rather than the value
        //we need to make sure this is the case
        if (cellData == value)
            //returning the corredt value but this is not rendering in the UI
            return label;
    }
    return "";
}

function onColumnStyle(ui) {
    return ""
}

function onColumnCls(ui, column) {
    return this.onEditable(ui, column) ? "cell-edit-apply" : ""
}


function onGrdCellButtonClick(ui, event) {
    var rowIndx = ui.rowIndx
    emit('CellButtonClick', this, ui, event)
}

function GridExport() {
    var blob = grid.value.exportData({
        //url: "/pro/demos/exportData",
        format: 'xlsx',
        render: true,
        type: 'blob'
    });
    saveAs(blob, "pqGrid.xlsx");
}

function gridImport(file) {
    if (file) {
        grid.value.showLoading();
        //import first sheet of xlsx into workbook.
        pq.excel.importXl({ file: file, sheets: [0] }, function (wb) {
            //import workbook into grid.                            
            grid.value.importWb({
                workbook: wb, keepCM: true, headerRowIndx: 0
            });
            grid.value.hideLoading();
        })
    }
}

/**
 * 그리드 Resize처리
 * 설명 : Resize발생시 호출해야하는 메소드
 */
function resize() {
    if (grid.value['refreshDataAndView'] !== undefined) {
        grid.value.refreshDataAndView()
        //console.log(this.ComID + ' : resize')
    }
}

/**
 * 그리드 새로 고침 처리
 * 설명 : 그리드 새로 고침이 필요할때 호출
 */
function refresh() {
    if (grid.value['refreshDataAndView'] !== undefined) {
        grid.value.refreshDataAndView()
    }
}

// ===============================================================
// 개인화 관련 : START
// ===============================================================
// 개인화 저장
function saveColumn(evt, ui) {
    function getColumns(col) {
        var data = {
            MENU_CODE: menuCode,
            REF_CODE: refCode,
            COMPONENT_CODE: col.CODE,
            SEQ: col.SEQ,
            WIDTH: col.width,
            HALIGN: col.halign,
            FIX_YN: 'N',
            PARENT_SEQ: col.parent ? col.parent.SEQ : null,
            HIDDEN_YN: col.hidden == true ? 'Y' : 'N',
            SORT: col.leftPos,
        }
        if ((grid.value.options.freezeCols || '') != '' && grid.value.options.freezeCols == (col.SEQ - 1)) {
            data['FIX_YN'] = 'Y'
        }
        paramsdata.push(data);
        if (col.parent) {
            var findCol = paramsdata.find((el) => el.SEQ == col.parent.SEQ);
            if (!findCol) {
                getColumns(col.parent);
            }
        }
    }

    var paramsdata = [];
    var rmr = 0;
    for (var c = 0; c < grid.value.colModel.length; c++) {
        if (grid.value.colModel[c].dataIndx == 'ROWRADIO' || grid.value.colModel[c].dataIndx == 'METHOD' || grid.value.colModel[c].dataIndx == 'ROWCHECK') {
            rmr++;
            continue;
        }
        getColumns(grid.value.colModel[c]);

    }

    // 저장기능
    store.dispatch("NGCore/saveSysData", { METHOD_CODE: 'MENU_COMPONENT_DTL_PERSON', paramsdata }).then((rtndata) => {
        const tmpAlert = vfmBasePopup(Alert, { state: 'info', contents: rtndata.rtndata.P_RETURN_MSG_CODE })

    }).catch((error) => {
        const tmpAlert = vfmBasePopup(Alert, { state: 'info', contents: 'MSG.MSG0023' })

        console.log('saveColumn')
        console.dir(error)
    })
    // this.$store.dispatch('nuisys/comonsyssave', params).then(
    //     (rtndata) => {
    //         vm.$NUAlert({ data: { state: 'info', contents: vm.$t(rtndata.msg), buttons: ['ok'] } })
    //     },
    //     (error) => {
    //         vm.$NUAlert({ data: { state: 'error', contents: vm.$t('ERRORMSG0009'), buttons: ['ok'] } })
    //     }
    // );
}

// 개인화 초기화
function resetColumn(evt, ui) {
    grid.value.destroy()
    baseComponentData.MENU_APPLY_COMPONENT_DTL_PERSON_COLUMN = []
    GridInit()
    saveColumn()
}

function hideColumn(arrColIndex) {

}

/** 그리드 초기화 */
function destroy() {
    grid.value.destroy()
}


// ===============================================================
// 개인화 관련 : END
// ===============================================================

</script>

<template>
    <div ref="grd" :options="options" style="width:100%; height:100%"></div>
</template>

<style>
.yellow {
    color: block;
    background-color: yellow;
}


.cell-edit-apply {
    background-color: rgba(230, 228, 119, 0.527);
}

.gridupload label {
    display: inline-block;
    padding: .1em .35em;
    color: #999;
    /* font-size: inherit; */
    font-size: .84em;
    line-height: normal;
    vertical-align: middle;
    background-color: #fdfdfd;
    cursor: pointer;
    border: 1px solid #ebebeb;
    border-bottom-color: #e2e2e2;
    border-radius: .25em;
}
.gridupload button {
    display: inline-block;
    padding: .1em .35em;
    color: rgb(255, 247, 247);
    /* font-size: inherit; */
    font-size: .8em;
    line-height: normal;
    vertical-align: middle;
    background-color: #ff2b2b;
    cursor: pointer;
    border: 1px solid #f52020;
    border-bottom-color: #f31e1e;
    margin-right: .25em;
    border-radius: .25em;
}
.gridupload input[type="file"] { 
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip:rect(0,0,0,0);
    border: 0;
}
</style>