<script setup>
import { ref, getCurrentInstance, onMounted, nextTick, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'
import Comfirm from '@/components//popup/Comfirm.vue'
import DataSelectPopup from '@/components/popup/DataSelectPopup.vue'
import CloneComponentPopup from './popup/CloneComponentPopup.vue'
import { useStore } from "vuex";

const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
const store = useStore()

let con = getCurrentInstance()
let search = {}

// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for (let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for (let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}
const VIEW = ref()
const VIEW_COMPONENT_DTL_PROP = ref()
const VIEW_COMPONENT_MST = ref()
const VIEW_COMPONENT_DTL_OPTION = ref()
const VIEW_COMPONENT_DTL_COLUMN = ref()
const VIEW_COMPONENT_DTL_HEADER = ref()
const VIEW_COMPONENT_DTL_COLUMN_W_H = ref()

let grids = {
    'VIEW': VIEW,
    'VIEW_COMPONENT_DTL_PROP': VIEW_COMPONENT_DTL_PROP,
    'VIEW_COMPONENT_DTL_OPTION': VIEW_COMPONENT_DTL_OPTION,
    'VIEW_COMPONENT_DTL_COLUMN': VIEW_COMPONENT_DTL_COLUMN,
    'VIEW_COMPONENT_DTL_HEADER': VIEW_COMPONENT_DTL_HEADER,
    'VIEW_COMPONENT_DTL_COLUMN_W_H': VIEW_COMPONENT_DTL_COLUMN_W_H,
    'VIEW_COMPONENT_MST': VIEW_COMPONENT_MST,
}

onMounted(() => {
    console.log('==== 컨포넌트 리스트 ====')
    let tmpGridKeys = Object.keys(ng_core.common.currentPageInfo)

    for(let i = 0; i < tmpGridKeys.length; i++) {
        console.log('** ' + tmpGridKeys[i] /**+ ' : ' + t('COMPONENT.' + tmpGridKeys[i])*/)
        let tmpSearchKeys = Object.keys(search[tmpGridKeys[i]])
        let tmpSearchColumn = ' -- Search : { '
        for(let j = 0; j < tmpSearchKeys.length; j++) {
            tmpSearchColumn += tmpSearchKeys[j] + ' '
        }
        tmpSearchColumn += '}'
        
        console.log(tmpSearchColumn)
    }
})
/*************************************************
 * Grid 관련 : Start
 *************************************************/

 /** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])
}

/** 조회 */
function onSearch2(compName) {
    for(let i = 0; i < compName.length; i++) {
        grids[compName[i]].value.grdSearchRef(search[compName[i]])
    }
}

function onComponentClone(compName) {
    let paramsPopup = {
        modalName: 'Component Clone',
        title: 'Component Clone',
        width: '1000px !important',
        height: '500px !important',
        grid: 'COMPONENT_MST',
        componentList: store.state.NGCore.currentPageInfo,
        targetREFCODEList: [''], buttons: ['ok']
    }

    vfmBasePopup(CloneComponentPopup, paramsPopup).then(async (rtn) => {
        if(rtn != false) {
            var tmpDataLis = rtn
            if(tmpDataLis.length === 0) {
                await vfmBasePopup(Alert, { state: 'info', contents: t('이동할 항목을 선택하여 주세요.') })
                return
            }
            tmpDataLis.map(function(row) {
                const mstgridrow = grids['VIEW'].value.getRowSelectedDataByRadio()
                if(!!mstgridrow) {
                    row.VIEW_CODE = mstgridrow.VIEW_CODE;
                }
                var tmp_row = Object.assign({}, row, {
                    ROWRADIO: 'N',
                    METHOD: 'A',
                    ROWCHECK: 'N',
                    // COLUMN_SEQ: row.SEQ
                })
                // treegrid 상위 처리 관계 없이 끝에 row추가
                grids[compName].value.grdAddRow(tmp_row, 'last')
            })
            // sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.VIEW_CODE)
        }
    })
}

/** 행추가 */
function onAddRow(compName) {
    let tmpAddData = {}
    if(compName == 'VIEW_COMPONENT_MST') {
        let tmpData = grids['VIEW'].value.getRowSelectedData()
        tmpAddData['VIEW_CODE'] = tmpData['VIEW_CODE']
    } else if(!['VIEW', 'VIEW_COMPONENT_MST'].includes(compName)) {
        let tmpData = grids['VIEW_COMPONENT_MST'].value.getRowSelectedData()
        tmpAddData['VIEW_CODE'] = tmpData['VIEW_CODE']
        tmpAddData['COMPONENT_CODE'] = tmpData['COMPONENT_CODE']
        tmpAddData['REF_CODE'] = tmpData['REF_CODE']
    }
    grids[compName].value.grdAddRow()
}

/** 행 삭제 */
function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

/** 저장 */
function onSave(compName) {
    grids[compName].value.grdSave()
}

/** 행 선택 라디오 */
function onRowClickRadio(ui, row) {
    search.VIEW_COMPONENT_MST.VIEW_CODE.value = row.VIEW_CODE
    onSearch('VIEW_COMPONENT_MST')
}

/** 행 선택 라디오2 */
function onRowClickRadio2(ui, row) {
    for(let i = 0; i < tabGrid.length; i++) {
        for(let j = 0; j < tabGrid[i].length; j++) {
            let tmpGrdNm = tabGrid[i][j]
            search[tmpGrdNm].VIEW_CODE.value = row.VIEW_CODE
            search[tmpGrdNm].COMPONENT_CODE.value = row.COMPONENT_CODE
            search[tmpGrdNm].REF_CODE.value = row.REF_CODE
            grids[tmpGrdNm].value.grdSearchRef(search[tmpGrdNm])
        }
    }
}

// /** 셀 버튼 이벤트 */
async function onGrdCellButton(sender, ui, event) {
    debugger;
    var gridstr = ui.rowData.MENU_TYPE;
    if (gridstr == 'PAGE' || gridstr == 'BOARD') {
        let paramsPopup = {
            modalName: 'DataBindingList',
            title: 'Data Binding List',
            width: '1000px !important',
            height: '500px !important',
            grid: gridstr,
            componentList: store.state.NGCore.currentPageInfo,
            targetREFCODEList: ['PAGE', 'BOARD'], buttons: ['ok']
        }

        vfmBasePopup(DataSelectPopup, paramsPopup).then(rtn => {
            if(rtn != false) {
                sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.VIEW_CODE)
            }
        })
    }
}
function onGrd02InitFinish(sender, ui, event) {

}

/*************************************************
 * Tab 관련 : Start
*************************************************/
let tabIndex = ref(0)
let tabGrid = [
    ['VIEW_COMPONENT_DTL_PROP'],
    ['VIEW_COMPONENT_DTL_OPTION'],
    ['VIEW_COMPONENT_DTL_COLUMN'],
    ['VIEW_COMPONENT_DTL_HEADER', 'VIEW_COMPONENT_DTL_COLUMN_W_H'],
]
/*************************************************
 * Grid 관련 : End
 *************************************************/

/*************************************************
 * Tab 관련 : Start
*************************************************/
/** 탭 이동 */
function activateTab(a, b, c) {
    // 탭 페이지 리사이징
    tabPageContentResize()
}

/** 탭 리사이즈 */
function tabPageContentResize() {
    window.requestAnimationFrame(function () {
        let tmpGridKeys = Object.keys(grids)
        for (let i = 0; i < tmpGridKeys.length; i++) {
            grids[tmpGridKeys[i]].value.refresh()
        }
    });
}
/*************************************************
 * Tab 관련 : End
 *************************************************/

/** 컬럼 불러오기 */
async function onLoadCloumn() {
    grids['VIEW_COMPONENT_DTL_COLUMN'].value.showloadelement()

    if((search['VIEW_COMPONENT_DTL_COLUMN'].COMPONENT_CODE.value || '') == '') {
        // 컨포넌트를 먼저 선택하세요.
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.CHKCOMPONENTCODE') })
        return 
    }

    let tmpSelectedRowData = grids['VIEW_COMPONENT_MST'].value.getRowSelectedData()
    ng_core.api.baseCoreApi.getComponentColumn(tmpSelectedRowData.SYSTEM_TYPE, tmpSelectedRowData.COMPONENT_CODE).then(rtn => {
        if(rtn.data.status) {
            console.log(grids)
            let rtndata = rtn.data.rtndata

            grids['VIEW_COMPONENT_DTL_COLUMN'].value.beginupdate()

            // 현재 리스트를 가져옴
            var oldrows = grids['VIEW_COMPONENT_DTL_COLUMN'].value.getrows()
            var tmp_rtndata = rtndata.filter(row => {
                // 받아온 데이터 확인

                try {
                    var tmp = oldrows.filter(row_sub => {
                        // 기존 데이터를 가져와 받아온 데이터와 비교 처리
                        if(row['COMPONENT_CODE'] === row_sub['COMPONENT_CODE'] 
                        && row['COMPONENT_CODE'] === row_sub['COMPONENT_CODE'] 
                        && row['COLUMN_CODE'] === row_sub['COLUMN_CODE'] 
                        && row['FIELD_CODE'] === row_sub['FIELD_CODE']) {
                            return true     // 최종 제외 대상
                        } else {
                            return false
                        }
                    })
                    // 대상이 발견되면 최종 대상으로 처리
                    if(tmp.length >= 1) return false
                    return true

                } catch(err) {
                    console.log('onLoadCloumn')
                    console.dir(err)
                }
            })

            // 추가 대상을 넣음
            tmp_rtndata.map(row => {
                row['VIEW_CODE'] = tmpSelectedRowData['VIEW_CODE']
                row['COMPONENT_CODE'] = tmpSelectedRowData['COMPONENT_CODE']
                row['REF_CODE'] = tmpSelectedRowData['REF_CODE']
                grids['VIEW_COMPONENT_DTL_COLUMN'].value.grdAddRow(row, 'last')
            })
            grids['VIEW_COMPONENT_DTL_COLUMN'].value.endupdate()
        } else {
            alert('??????')
        }
        grids['VIEW_COMPONENT_DTL_COLUMN'].value.hideloadelement()

    }).catch(err => {
        // 처리 중 오류 발생
        vfmBasePopup(Alert, { state: 'info', contents: t('ERRORMSG.ERRORMSG0009') })

        grids['VIEW_COMPONENT_DTL_COLUMN'].value.hideloadelement()
        let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
        store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })
    })
}

/** 대상을 왼쪽으로 이동 */
async function onLeftMove() {
    debugger
    let tmpDataList = grids['VIEW_COMPONENT_DTL_COLUMN_W_H'].value.getGrdCheckList()
    if(tmpDataList.length == 0) {
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0013') })
        return 
    }
    let rtnComfirm = await vfmBasePopup(Comfirm, { state: 'info', contents: t('MSG.CHK_ADD') })
    if(rtnComfirm == false) return

    tmpDataList.map(function(row) {
        var tmp_row = Object.assign({}, row, {
            ROWRADIO: 'N',
            METHOD: 'A',
            ROWCHECK: 'N',
            COLUMN_SEQ: row.SEQ,
            VIEW_CODE: row.VIEW_CODE,
            COMPONENT_CODE: row.COMPONENT_CODE,
            REF_CODE: row.REF_CODE,

        })
        // 상위 정보를 같이 넘겨야 함****
        //self.$refs.VIEW_COMPONENT_DTL_HEADER.grdAddRowChild(tmp_row)       
        // treegrid 상위 처리 관계 없이 끝에 row추가
        grids['VIEW_COMPONENT_DTL_HEADER'].value.grdAddRow(tmp_row, 'last')
    })

    await grids['VIEW_COMPONENT_DTL_HEADER'].value.grdSave({ hideComfirm: 'Y'/**, hideMessageYN: 'Y', reloadYn: 'N' */ })

    //grids['VIEW_COMPONENT_DTL_HEADER'].value.grdSearchRef(null, null, { hideMessageYN: 'Y' })
    grids['VIEW_COMPONENT_DTL_COLUMN_W_H'].value.grdSearchRef(null, null, { hideMessageYN: 'Y' })
}

/** 대상을 오른쪽으로 이동 */
async function onRightMove() {
    let tmpDataList = grids['VIEW_COMPONENT_DTL_HEADER'].value.getGrdCheckList()
    if(tmpDataList.length == 0) {
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0013') })
        return 
    }

    let rtn = await grids['VIEW_COMPONENT_DTL_HEADER'].value.grdDeleteWithDB()
    if(rtn) {
        grids['VIEW_COMPONENT_DTL_HEADER'].value.grdSearchRef()
        grids['VIEW_COMPONENT_DTL_COLUMN_W_H'].value.grdSearchRef()
    } else {
        alert('???????')
    }
}

</script>
<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal @resize="doResize"
                :dbl-click-splitter="false">
                <Pane>
                    <Splitpanes :orientation="'horizontal'" @resize="doResize">
                        <Pane>
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="parentCon">
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{ t('VIEW_CODE') }}</label>
                                        <input type="text" class="conInputText" v-model="search['VIEW'].VIEW_CODE.value" :placeholder="t('VIEW_CODE')" 
                                        @keyup.enter="onSearch(['VIEW'])" :state="null">
                                    </div>
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{ t('VIEW_NAME') }}</label>
                                        <input type="text" class="conInputText" v-model="search['VIEW'].VIEW_NAME.value" :placeholder="t('VIEW_NAME')" 
                                        @keyup.enter="onSearch(['VIEW'])" :state="null">
                                    </div>
                                    <div class="rightCon">
                                        <button ref="read" @click="onSearch(['VIEW'])" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                        <button ref="plus" @click="onAddRow('VIEW')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('VIEW')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('VIEW')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003') }}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 40px);">
                                <BaseGrid ref="VIEW" :refCode="'VIEW'"
                                @RowClickRadio="onRowClickRadio"/>
                            </div>
                        </Pane>
                        <Pane>
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="parentCon">
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{$t('COMPONENT_CODE')}}</label>
                                        <input type="text" class="conInputText" v-model="search['VIEW_COMPONENT_MST'].COMPONENT_CODE.value" :placeholder="$t('COMPONENT_CODE')"
                                            @keyup.enter="onSearch2(['VIEW_COMPONENT_MST'])" :state="null">
                                    </div>
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{$t('COMPONENT_TYPE')}}</label>
                                        <input type="text" class="conInputText" v-model="search['VIEW_COMPONENT_MST'].COMPONENT_TYPE.value" :placeholder="$t('COMPONENT_TYPE')"
                                            @keyup.enter="onSearch2(['VIEW_COMPONENT_MST'])" :state="null">
                                    </div>
                                    <div class="rightCon">
                                        <button ref="clone" @click="onComponentClone('VIEW_COMPONENT_MST')" :title="$t('BUTTON.BUTTON0016')"><font-awesome-icon icon="clone" /></button>

                                        <button ref="read" @click="onSearch2(['VIEW_COMPONENT_MST'])" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                        <button ref="plus" @click="onAddRow('VIEW_COMPONENT_MST')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('VIEW_COMPONENT_MST')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('VIEW_COMPONENT_MST')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 40px);">
                                <BaseGrid ref='VIEW_COMPONENT_MST' :refCode="'VIEW_COMPONENT_MST'" 
                                @RowClickRadio="onRowClickRadio2"
                                />
                            </div>
                        </Pane>
                    </Splitpanes>
                </Pane>
                <Pane>
                    <b-tabs ref="bottomTabs" small class="h-100" @activate-tab="activateTab">
                        <b-tab :title="$t('ATTR_MANAGE')" active>
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="parentCon">
                                </div>
                                <div class="rightCon">
                                    <button ref="read" @click="onSearch2(['VIEW_COMPONENT_DTL_PROP'])" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                    <button ref="plus" @click="onAddRow('VIEW_COMPONENT_DTL_PROP')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                    <button ref="minus" @click="onRemoveRow('VIEW_COMPONENT_DTL_PROP')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                    <button ref="save" @click="onSave('VIEW_COMPONENT_DTL_PROP')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref='VIEW_COMPONENT_DTL_PROP' :refCode="'VIEW_COMPONENT_DTL_PROP'" 
                                @RowClickRadio="onRowClickRadio2"/>
                            </div>
                        </b-tab>
                        <b-tab :title="$t('OPTION_MANAGE')">
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="parentCon">
                                    <div class="rightCon">
                                        <button ref="read" @click="onSearch2(['VIEW_COMPONENT_DTL_OPTION'])" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                        <button ref="plus" @click="onAddRow('VIEW_COMPONENT_DTL_OPTION')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('VIEW_COMPONENT_DTL_OPTION')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('VIEW_COMPONENT_DTL_OPTION')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height: calc(100% - 80px)">
                                <BaseGrid ref='VIEW_COMPONENT_DTL_OPTION' :refCode="'VIEW_COMPONENT_DTL_OPTION'"
                                @RowClickRadio="onRowClickRadio2"
                                />
                            </div>
                        </b-tab>
                        <b-tab :title="$t('COLUMN_MANAGE')">
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="parentCon">
                                    <div class="rightCon">
                                        <button ref="loadColumn" @click="onLoadCloumn()" :title="t('LABEL.LOAD_COLUMN')">{{t('LABEL.LOAD_COLUMN')}}</button>
                                        <button ref="read" @click="onSearch2(['VIEW_COMPONENT_DTL_COLUMN'])" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                        <button ref="plus" @click="onAddRow('VIEW_COMPONENT_DTL_COLUMN')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('VIEW_COMPONENT_DTL_COLUMN')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('VIEW_COMPONENT_DTL_COLUMN')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height: calc(100% - 80px)">
                                <BaseGrid ref='VIEW_COMPONENT_DTL_COLUMN' :refCode="'VIEW_COMPONENT_DTL_COLUMN'"
                                    @RowClickRadio="onRowClickRadio2"
                                    @CellButtonClick="onGrdCellButton"/>
                            </div>
                        </b-tab>
                        <b-tab :title="t('HEADER_MANAGE')" >
                            <Splitpanes @resize="doResize">
                                <Pane>
                                    <div style="width:100%; height:40px; overflow:auto;">
                                        <div class="parentCon">
                                            <div class="rightCon">
                                                <button ref="read05" @click="onSearch2(['VIEW_COMPONENT_DTL_HEADER', 'VIEW_COMPONENT_DTL_COLUMN_W_H'])" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                                <button ref="plus05" @click="onAddRow('VIEW_COMPONENT_DTL_HEADER')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                                <button ref="minus05" @click="onRemoveRow('VIEW_COMPONENT_DTL_HEADER')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                                <button ref="save05" @click="onSave('VIEW_COMPONENT_DTL_HEADER')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                                                <button @click="onLeftMove()" :title="t('BUTTON0014')"><font-awesome-icon icon="angle-left" /></button>
                                                <button @click="onRightMove()" :title="t('BUTTON0015')"><font-awesome-icon icon="angle-right" /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="resultGrid" style="height:calc(100% - 80px);">
                                        <BaseGrid ref="VIEW_COMPONENT_DTL_HEADER" :refCode="'VIEW_COMPONENT_DTL_HEADER'"
                                            @RowClickRadio="onRowClickRadio" />
                                    </div>
                                </Pane>
                                <Pane>
                                    <div style="width:100%; height:40px; overflow:auto;">
                                        <div class="parentCon">
                                        </div>
                                    </div>
                                    <div class="resultGrid" style="height:calc(100% - 80px);">
                                        <BaseGrid ref="VIEW_COMPONENT_DTL_COLUMN_W_H" :refCode="'VIEW_COMPONENT_DTL_COLUMN_W_H'"
                                            @RowClickRadio="onRowClickRadio" />
                                    </div>
                                </Pane>
                            </Splitpanes>   
                        </b-tab>
                    </b-tabs>
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>