<script setup>

// https://vue-final-modal.org/examples/liveDemo : 참고
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { defineProps, defineEmits, onMounted, computed } from 'vue'
// import { Modal } from 'bootstrap'

const props = defineProps(['state', 'title', 'contents', 'width', 'height', 'drag'])
const emit = defineEmits(['confirm', 'cancel'])

const { t } = useI18n()

// 사이즈
let modalSize = computed(() => {
    let styles = {
        //width: (props.width || '') == '' ? '0px' : props.width,
        //height: (props.height || '') == '' ? '0px' : props.height,
    }
    if((props.width || '') != '') styles['width'] = props.width
    if((props.height || '') != '') styles['height'] = props.height
    
    return styles
})
// 헤더 상태
let modalHeaderState = computed(() => {
    switch (props.state) {
        case 'info':
            return t('STATUS.STATUS0002')
        case 'warning':
            return t('STATUS.STATUS0003')
        case 'error':
            return t('STATUS.STATUS0004')
        case 'none' :
            return ''
        default:
            return t('STATUS.STATUS0001')
    }
})

function onClick(close) {
    emit('confirm', close, true)
}

</script>

<template>
    <!-- First modal -->
    <vue-final-modal v-slot="{ params, close }" classes="modal-container" content-class="modal-content2" :drag="(props.drag || '') == '' ? flase : drag"
        :click-to-close="false" transition="" overlay-transition="" >
        <div class="border-bottom">
            <button class="btn-close modal__close" @click="onClick(close)"></button>
            <span class="modal__title">{{ modalHeaderState }}{{ props.title }}</span>
        </div>
        <div class="modal__content" :style="modalSize">
            {{ t(contents) }}
        </div>
        <div class="modal__action">
            <!-- <button type="button" class="btn btn-primary" @click="$emit('cancel', close)">{{ t('BUTTON.BUTTON0001')}}</button> -->
            <button type="button" class="btn btn-primary" @click="onClick(close)">{{ t('BUTTON.BUTTON0001') }}</button>
        </div>
    </vue-final-modal>
</template>

<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .modal-content2 {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}

.modal__title {
    margin: 0 2rem 0.5rem 0;
    font-size: 1.5rem;
    font-weight: 700;
}

.modal__content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 5px;
}

.modal__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 1rem 0 0;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content2 {
    border-color: #2d3748;
    background-color: #1a202c;
}
</style>