<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'

const { t } = useI18n()

let con = getCurrentInstance()
let search = {}

const COMMON_CODE_MST = ref()
const COMMON_CODE_DTL = ref()

let grids = {
    'COMMON_CODE_MST': COMMON_CODE_MST,
    'COMMON_CODE_DTL': COMMON_CODE_DTL,
}
// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for(let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for(let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

onMounted(() => {
    
})

/** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])
}

function onAddRow(compName) {
    let tmpData = {}
    if(compName == 'COMMON_CODE_DTL') {
        tmpData['GROUP_CODE'] = grids['COMMON_CODE_MST'].value.getRowSelectedData().GROUP_CODE
    }
    grids[compName].value.grdAddRow(tmpData)
}

function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

function onSave(compName) {
    grids[compName].value.grdSave()
}

function onRowClickRadio(ui, row) {
    search.COMMON_CODE_DTL.GROUP_CODE.value = row.GROUP_CODE
    onSearch('COMMON_CODE_DTL')
}


</script>

<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter"
                        horizontal
                        @resize="doResize" :dbl-click-splitter="false">
                <Pane>
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="parentCon">
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('GROUP_CODE')}}</label>
                                <input type="text" class="conInputText" v-model="search['COMMON_CODE_MST'].GROUP_CODE.value" :placeholder="t('GROUP_CODE')"
                                        @keyup.enter="onSearch('COMMON_CODE_MST')" :state="null">
                            </div>
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('NAME')}}</label>
                                <input type="text" class="conInputText" v-model="search['COMMON_CODE_MST'].NAME.value" :placeholder="t('NAME')"
                                        @keyup.enter="onSearch('COMMON_CODE_MST')" :state="null">
                            </div>
                            <div class="rightCon">
                                <button ref="read" @click="onSearch('COMMON_CODE_MST')" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                <button ref="plus" @click="onAddRow('COMMON_CODE_MST')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                <button ref="minus" @click="onRemoveRow('COMMON_CODE_MST')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                <button ref="save" @click="onSave('COMMON_CODE_MST')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="resultGrid" style="height:calc(100% - 40px);">
                        <BaseGrid ref="COMMON_CODE_MST" :refCode="'COMMON_CODE_MST'"
                            @RowClickRadio="onRowClickRadio"
                        />
                    </div>
                </Pane>
                <Pane>
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="parentCon">
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('CODE')}}</label>
                                <input type="text" class="conInputText" v-model="search['COMMON_CODE_DTL'].CODE.value" :placeholder="t('CODE')"
                                        @keyup.enter="onSearch('COMMON_CODE_DTL')" :state="null">
                            </div>
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('NAME')}}</label>
                                <input type="text" class="conInputText" v-model="search['COMMON_CODE_DTL'].NAME.value" :placeholder="t('NAME')"
                                        @keyup.enter="onSearch('COMMON_CODE_DTL')" :state="null">
                            </div>

                            <div class="rightCon">
                                <button ref="read" @click="onSearch('COMMON_CODE_DTL')" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                <button ref="plus" @click="onAddRow('COMMON_CODE_DTL')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                <button ref="minus" @click="onRemoveRow('COMMON_CODE_DTL')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                <button ref="save" @click="onSave('COMMON_CODE_DTL')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="resultGrid" style="height:calc(100% - 40px);">
                        <BaseGrid ref='COMMON_CODE_DTL' :refCode="'COMMON_CODE_DTL'" @rowclick="onGrd02RowClick"/>
                        <!-- <BaseGrid ref='COMMON_CODE_DTL' 
                                @rowclick="onGrd02RowClick"
                                v-bind:ComID="'COMMON_CODE_DTL'"
                                v-bind:MainCode="grd02basedata.COMPONENT_CODE"/> -->
                    </div>       
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>