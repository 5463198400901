<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'

const { t } = useI18n()

let con = getCurrentInstance()
let search = {}

const EVENT_CATEGORY_MST = ref()

let grids = {
    'EVENT_CATEGORY_MST': EVENT_CATEGORY_MST
}

// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for(let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for(let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])
}

function onAddRow(compName) {
    grids[compName].value.grdAddRow({}, 'last')
}

function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

/** 저장 */
function onSave(compName) {
    grids[compName].value.grdSave()
}

</script>

<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter"
                        horizontal
                        @resize="doResize" :dbl-click-splitter="false">
            <!-- 조회 상단-->
            <Pane>
                <div style="width:100%; height:40px; overflow:auto;">
                    <div class="parentCon">
                        <div class="leftCon ml-1 mr-1">
                            <label for="input-none" class="conLabel">{{t('RUN_USER_ID')}}</label>
                            <input type="text" class="conInputText" v-model="search['EVENT_CATEGORY_MST'].RUN_USER_ID.value" :placeholder="t('PRODUCT_CODE')"
                                    @keyup.enter="onSearch('EVENT_CATEGORY_MST')" :state="null">
                        </div>

                        <div class="rightCon">
                            <button ref="read" @click="onSearch('EVENT_CATEGORY_MST')" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                            <button ref="plus" @click="onAddRow('EVENT_CATEGORY_MST')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                            <button ref="minus" @click="onRemoveRow('EVENT_CATEGORY_MST')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                            <button ref="save" @click="onSave('EVENT_CATEGORY_MST')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                        </div>
                    </div>
                </div>
                <div class="resultGrid" style="height:calc(100% - 40px);">
                    <BaseGrid ref="EVENT_CATEGORY_MST" :refCode="'EVENT_CATEGORY_MST'"/>
                </div>

            </Pane>
            
        </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>