import { defineAsyncComponent, inject } from 'vue'
import { createWebHistory, createRouter } from 'vue-router'

const vfmBasePopup = inject('vfmBasePopup')

// import { isSSR } from "@/helpers"
// const createHistory = isSSR()
//     ? createMemoryHistory
//     : createWebHistory

const routes = [
	{ path: '/', redirect: '/' + noligo_config.menuAreaType },
	{	//  로그인 관련
		path: '/login',
		name: 'login',
		// lazy-loaded
		component: () => import('../views/login.vue'),
	},
	{	// 메인 최상단
		path: '/' + noligo_config.menuAreaType,
		name: noligo_config.menuAreaType,
		// lazy-loaded
		component: () => import('/src/views/' + noligo_config.menuAreaType  + '/Main.vue'),
		meta: { requiresLogin: true },
		children: [
			{ path: '', redirect: '/' + noligo_config.menuAreaType  + '/notfile'},
			{
				path: 'notfile',
				name: 'notfile',
				// lazy-loaded
				component: () => import('/src/views/notfile.vue')
			},
			// // 임시 메인 화면
			// {
			// 	path: '/main/board/:board',
			// 	name: 'BOARD',
			// 	props: {auth: 'N'},
			// 	// lazy-loaded
			// 	component: () => import('/src/views/board/BoardList.vue')
			// },
			// {
			// 	path: '/main/board/:board/:idx',
			// 	name: 'BOARDDETAIL',
			// 	props: {auth: 'N'},
			// 	// lazy-loaded
			// 	component: () => import('/src/views/board/BoardDetail.vue')
			// },
		]
	},
	{	//  404 에러
		path: '/page404',
		name: 'page404',
		// lazy-loaded
		component: () => import('../views/page404.vue'),
	},
]

var retryCount = 0	// 라운터 재호출 시도 횟수
export default async function (store, i18n) {
	// 테마 설정

	const router = createRouter({
		routes,
		history: createWebHistory() //createHistory(process.env.BASE_URL)
	})

	router.beforeEach(async (to, from, next) => {
		// 현재 메뉴가 바인딩 되었는지 체크
		let bChkMenu = false
		if((to.name || '') == '') {
			bChkMenu = true
		}
		if(store.state.NGCore.ngCoreReadyYn == 'N') {		// NGCore 초기화 처리
			await store.dispatch("NGCore/init")
			await store.commit('NGCore/syncNGCore')
			i18n.global.setLocaleMessage(ng_core.common.currentLang.toLowerCase(), ng_core.common.langPack[ng_core.common.currentLang.toUpperCase()])

			// 테마 설정

			// 메뉴 라우터에 세팅
			if(ng_core.common.menu.length > 0) {
				let { routes } = router.options
				let mainRouter = routes.filter(row => row.name == noligo_config.menuAreaType)
				if(mainRouter.length == 1) {
					// 메인 메뉴 초기화
					for(let i = 0; i < mainRouter[0].children.length; i++) {
						let tmpMenuCode = mainRouter[0].children[i].name
						if(!['notfile'].includes(tmpMenuCode)) {
							router.removeRoute(tmpMenuCode)
						}
					}

					// 메뉴 생성(재귀)
					AppendRouter(router, '', ng_core.common.menu, 1)

					next({ path: to.fullPath })
					return
				}
			}
		}

		// 로그인 여부 확인
		if ((to.matched.length == 0 || to.matched.some(record => record.meta.requiresLogin)) && ng_core.common.isLogin() == 'N') {
			next({ path: '/login' })
			return
		}

		if(bChkMenu) {
			if(retryCount < 3) {
				// 라우팅중 메뉴가 새로 생성된 경우 재호출
				retryCount++
				next({ path: to.fullPath })
			} else {
				retryCount = 0
				next({ path: '/' + noligo_config.menuAreaType  + '/notfile' })
				
			}
			return
		}

		// 언어팩 등록(선택 언어가 달라질 경우, 최초의 경우메만 발동)
		if(i18n.global.locale.value != ng_core.common.currentLang) {
			i18n.global.setLocaleMessage(ng_core.common.currentLang.toLowerCase(), ng_core.common.langPack[ng_core.common.currentLang.toUpperCase()])
		}
		
		try {
			await ng_core.api.baseCoreApi.moveMenu(to.name)
			await ng_core.api.baseCoreApi.getCurrentMenuInfo(noligo_config.menuAreaType, to.meta.MENU_CODE)
		} catch(err) {
			console.log('beforeEach11')
			console.dir(err)
		}

		if((ng_core.common.currentMenuInfo.MENU_CODE || '') != '') {
			// 현재 메뉴정보 바인딩
			let tmpMenuInfo = ng_core.common.menuArr.filter(row => row.ROUTER_NAME == to.name)
			if(tmpMenuInfo.length == 1) {
				store.state.NGCore.currentMenuInfo = tmpMenuInfo[0]
			} else {
				store.state.NGCore.currentMenuInfo = {}
			}
			store.state.NGCore.currentPageInfo = ng_core.common.currentPageInfo

		}

		retryCount = 0
		next()
	})

	router.afterEach((to, from, next) => {
		
	})

	return router
}

/**
 * 계층 구조 라우터 생성
 * @param {Router} router 
 * @param {RouteRecordRaw[]} targetMenu 
 * @param {List<any>} sourceMenu 
 * @param {int} lv 
 */
async function AppendRouter(router, parentMenuPath, sourceMenu, lv = 1) {
	for(let i = 0; i < sourceMenu.length; i++) {
		let rawMenu = sourceMenu[i]
		rawMenu['requiresLogin'] = rawMenu.AUTH_YN
		let currentMenuPath = rawMenu.FULLPATH; //(lv == 1 ? '' : parentMenuPath + '_') + rawMenu.MENU_CODE.toLowerCase()
		let menu = {
			path: currentMenuPath,
			name: rawMenu.ROUTER_NAME,
			meta: rawMenu,
			props: { auth: rawMenu.AUTH_YN },
			children: [],
		}
		if(rawMenu.FILE_PATH != null && rawMenu.FILE_PATH != '') {
			try {
				require('/src/views' + rawMenu.FILE_PATH + '.vue')
				//menu['component'] = import('../views' + rawMenu.FILE_PATH + '.vue')
				const tmpCom = Promise.resolve({
					// The component to load (should be a Promise)
					component: import('../views' + rawMenu.FILE_PATH + '.vue'),
				})
				// menu['component'] = tmpCom.then(r => {
				// 	debugger
				// 	menu['component'] = r
				// })
				menu['component'] = await defineAsyncComponent(() => import('../views' + rawMenu.FILE_PATH + '.vue'))


			  } catch (e) {
				menu['component'] = import('../views/notfile.vue')
			  }			
		}
		if(rawMenu.CHILD_MENU.length > 0) {		// 하위가 있으면 반복
			AppendRouter(router, currentMenuPath, rawMenu.CHILD_MENU, lv+1)
		}
		router.addRoute(noligo_config.menuAreaType, menu)
	}
}

// const router = createRouter({
// 	history: createWebHistory(),
// 	routes,
// })



// export default router
